import { Box, Typography } from "@mui/material"
import { FC } from "react"
import { languages } from "../../../constants"
import { LanguageSelect } from "./LanguageSelect"

const availableLanguages = Object.entries(languages)

export const defaultOptions = availableLanguages.map(([value, { label, flag }]) => ({
    value,
    label,
    flag,
}))

export const TranslateLanguageForm: FC = () => {
    const options = [{ value: "auto", label: "Don't Translate", flag: "" }, ...defaultOptions]

    return (
        <Box display="flex" flexDirection="column">
            <Typography fontWeight={500} variant="h5">
                Translate Summaries
            </Typography>
            <Typography variant="caption" color="text.secondary">
                Choose a language for your summaries to be translated to. <br />
                If you select <b>Don't Translate</b>, the language of the original content will be
                used.
            </Typography>
            <LanguageSelect options={options} />
        </Box>
    )
}
