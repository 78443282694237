import { Database } from "@nozbe/watermelondb"
import { useDatabase } from "@nozbe/watermelondb/react"
import { mentionsService } from "../../services/links/mentionsService"
import { ItemModel } from "../../storage/watermelon"
import { connectionRepository } from "../../storage/watermelon/repository/connectionRepository"
import { itemRepository } from "../../storage/watermelon/repository/itemRepository"
import { sentry } from "../../utils"
import { ReferenceItem } from "../components/ReferenceElement"
import { Result } from "./useSearchItems"

export const usePluginHelpers = (item: ItemModel) => {
    const db = useDatabase()

    return {
        getItemByConnectionId: (id: string) => getItemByConnectionId(db, id),
        deleteConnectionById: (id: string) => deleteConnectionById(db, id),
        createConnection: (itemResult: Result) => createConnection(db, itemResult, item.id),
        updateImage: (url: string) => updateImage(item, url),
        isMainImage: (url: string) => isMainImage(item, url),
    }
}

export const getItemByConnectionId = async (
    db: Database,
    id: string
): Promise<ReferenceItem | null> => {
    try {
        const connection = await connectionRepository.get(db, id)
        if (!connection) return null

        const item = await connection.to
        if (!item) return null
        const mentions = await item.mentions
        const otherMentions = mentions.filter(({ id, isSaved }) => isSaved && id !== connection.id)

        return {
            id: item.id,
            name: item.name,
            description: item.description,
            image: item.image,
            mentionsCount: mentions.length,
            mentions: await mentionsService.getByConnections(otherMentions),
        }
    } catch (error) {
        sentry.captureException(error as Error)
        return null
    }
}

export const deleteConnectionById = async (db: Database, id: string) => {
    await connectionRepository.delete(db, [id])
}

export const createConnection = async (db: Database, item: Result, id: string) => {
    const itemModel = await itemRepository.getOrCreate(db, item)

    const connectionModel = await connectionRepository.create(db, {
        fromId: id,
        toId: itemModel.id,
    })

    return { name: itemModel.name, connectionId: connectionModel.id, itemId: itemModel.id }
}

export const updateImage = async (item: ItemModel, url: string) => {
    await item.updateImage(url)
}

export const isMainImage = (item: ItemModel, url: string) => item.image === url
