import { Image } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { focusEditor } from "@udecode/plate-common"
import { insertImage } from "@udecode/plate-media"
import { FC, useRef } from "react"
import { useMyPlateEditorRef } from "../../types"

interface Props {
    uploadImage: (arrayBuffer: ArrayBuffer, contentType: string) => Promise<string>
}

export const ImageToolbarButton: FC<Props> = ({ uploadImage }) => {
    const editor = useMyPlateEditorRef()
    const inputRef = useRef<HTMLInputElement>(null)

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]
        if (file) {
            const contentType = file.type
            const reader = new FileReader()
            reader.onloadend = async () => {
                if (reader.result instanceof ArrayBuffer) {
                    const arrayBuffer = reader.result

                    const url = await uploadImage(arrayBuffer, contentType)

                    if (!url) return null

                    insertImage(editor, url)
                    focusEditor(editor)
                }
            }
            reader.readAsArrayBuffer(file)
        }
    }

    return (
        <>
            <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={inputRef}
                onChange={handleImageChange}
            />
            <Tooltip title="Upload image">
                <IconButton
                    size={"small"}
                    onClick={() => {
                        inputRef.current?.click()
                    }}
                >
                    <Image />
                </IconButton>
            </Tooltip>
        </>
    )
}
