import { User, UserRepository } from "../../repositories"

export interface IUsageService {
    limit: number
    userRepository: UserRepository

    getUsage(userId: string): Promise<number>
    isUsageExceeded(userId: string): Promise<boolean>
    increaseUsage(userId: string, usage?: number | null): Promise<number>
    isUsageExceededByCount(usage: number): boolean
}

export abstract class UsageService {
    abstract limit: number
    userRepository: UserRepository

    constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    defaultUsage = { extension: 0, scraping: 0, cost: { value: 0, resetDate: Date.now() } }

    protected getUserUsage = (user: User | null, usage: Partial<User["usage"]>) => {
        return { ...(user?.usage || this.defaultUsage), ...usage }
    }

    isUsageExceededByCount = (usage: number) => {
        return usage >= this.limit
    }
}
