import { getPluginOptions, usePlateEditorRef, usePlateReadOnly } from "@udecode/plate-common"
import {
    flip,
    offset,
    shift,
    useVirtualFloating,
    UseVirtualFloatingOptions,
} from "@udecode/plate-floating"
import {
    ELEMENT_LINK,
    floatingLinkActions,
    LinkPlugin,
    useFloatingLinkSelectors,
} from "@udecode/plate-link"
import { useFocused } from "slate-react"
import { useIsMobile } from "../../hooks/useIsMobile"
import { getSelectionBoundingClientRect } from "./useFloatingToolbarState"

export type LinkFloatingToolbarState = {
    floatingOptions?: UseVirtualFloatingOptions
    isExtension?: boolean
}

export const useFloatingLinkInsertState = ({
    floatingOptions,
    isExtension = false,
}: LinkFloatingToolbarState = {}) => {
    const editor = usePlateEditorRef()
    const { triggerFloatingLinkHotkeys } = getPluginOptions<LinkPlugin>(editor, ELEMENT_LINK)
    const readOnly = usePlateReadOnly()
    const focused = useFocused()
    const mode = useFloatingLinkSelectors().mode()
    const isOpen = useFloatingLinkSelectors().isOpen(editor.id)
    const isMobile = useIsMobile()

    const floating = useVirtualFloatingLink({
        editorId: editor.id,
        open: isOpen && mode === "insert",
        whileElementsMounted: () => {},
        placement: "bottom",
        middleware: [
            offset(isMobile ? 55 : 10),
            flip({
                padding: 12,
            }),
            shift({ padding: 5 }),
        ],
        isExtension,
        ...floatingOptions,
    })

    return {
        editor,
        triggerFloatingLinkHotkeys,
        floating,
        focused,
        isOpen,
        readOnly,
    }
}

const useVirtualFloatingLink = ({
    editorId,
    isExtension,
    ...floatingOptions
}: { editorId: string; isExtension: boolean } & UseVirtualFloatingOptions) => {
    return useVirtualFloating({
        onOpenChange: (open) => floatingLinkActions.openEditorId(open ? editorId : null),
        getBoundingClientRect: () => getSelectionBoundingClientRect(isExtension),
        ...floatingOptions,
    })
}
