import { Database } from "@nozbe/watermelondb"
import { posthogService, ROOT_TAG_ID, ROOT_TAG_NAME, tagRepository } from "@recall/common"
import { User } from "firebase/auth"

export const initializeRootTag = async (db: Database) => {
    const rootTag = await tagRepository.get(db, ROOT_TAG_ID)

    if (rootTag) return

    await tagRepository.create({ db, name: ROOT_TAG_NAME, isSaved: true, id: ROOT_TAG_ID })
}

export const initializeUser = async (user: User) => {
    if (user.email) posthogService.setUser(user.email)
}
