import { languages } from "../constants"
import { buildUrl, HttpClient } from "../utils"

const WIKIPEDIA_BASE_URL = "https://en.wikipedia.org"
const WIKIDATA_BASE_URL = "https://www.wikidata.org/w/api.php"
const WIKIPEDIA_SUMMARY_URL = "/api/rest_v1/page/summary"

const httpClient = new HttpClient(WIKIPEDIA_BASE_URL)
const wikidataHttpClient = new HttpClient(WIKIDATA_BASE_URL)

const getSummary = async (sourceIdentifier: string) => {
    const url = `${WIKIPEDIA_SUMMARY_URL}/${sourceIdentifier}`
    try {
        return await httpClient.get(url)
    } catch {
        return null
    }
}

const getImageUrlForSource = async (sourceIdentifier: string) => {
    const response: any = await getSummary(sourceIdentifier)

    return response?.data?.thumbnail?.source || null
}

const getGroupedSlugsByLanguage = async (identifier: string): Promise<Record<string, string>> => {
    try {
        const response = await wikidataHttpClient.get(
            buildUrl("", {
                action: "wbgetentities",
                ids: identifier,
                props: "sitelinks/urls",
                format: "json",
                origin: "*",
            })
        )

        const data = await response.json()
        const availableLanguages = Object.keys(languages)
        const sitelinks = data?.entities?.[identifier]?.sitelinks || {}
        const slugsByLanguage: any = {}

        availableLanguages.forEach((lang) => {
            const wikiCode = `${lang}wiki`
            if (sitelinks[wikiCode]) {
                slugsByLanguage[lang] = sitelinks[wikiCode].title
            }
        })

        return slugsByLanguage
    } catch {
        return {}
    }
}

export const wikiService = { getImageUrlForSource, getGroupedSlugsByLanguage }
