import { Database, Q } from "@nozbe/watermelondb"
import { v4 as uuidv4 } from "uuid"
import { EditorOrderModel } from "../models/EditorOrderModel"
import { EDITOR_ORDERS } from "../schema"

const get = async (db: Database, itemId: string) => {
    let results = await db.collections
        .get<EditorOrderModel>(EDITOR_ORDERS)
        .query(Q.where("item_id", itemId))
        .fetch()

    return results?.[0] || null
}

const prepareUpsert = async (
    db: Database,
    itemId: string,
    editorOrder: string[],
    isSaved = true
) => {
    const editorOrderModel = await get(db, itemId)

    if (editorOrderModel) {
        return editorOrderModel.prepareUpdate((record) => {
            record.order = editorOrder
        })
    } else {
        return db.collections.get<EditorOrderModel>(EDITOR_ORDERS).prepareCreate((record) => {
            record._raw.id = uuidv4()
            record.item.id = itemId
            record.order = editorOrder
            record.isSaved = isSaved
        })
    }
}

const upsert = async (db: Database, itemId: string, editorOrder: string[], isSaved = true) => {
    return await db.write(async () => {
        const editorOrderModel = await get(db, itemId)

        if (editorOrderModel) {
            return editorOrderModel.update((record) => {
                record.order = editorOrder
            })
        } else {
            return db.collections.get<EditorOrderModel>(EDITOR_ORDERS).create((record) => {
                record._raw.id = uuidv4()
                record.item.id = itemId
                record.order = editorOrder
                record.isSaved = isSaved
            })
        }
    })
}

export const editorOrderRepository = { get, prepareUpsert, upsert }
