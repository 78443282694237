import { OnboardingStepsEnum, User, tutorials } from "@recall/common"
import { User as FirestoreUser } from "firebase/auth"
import { isEqual, uniq } from "lodash"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { userRepository } from "repositories/userRepository"
import { SET_ONBOARDING } from "storage/redux/user/actionTypes"

export const useInit = () => {
    const dispatch = useDispatch()

    const initOnboarding = useCallback(
        async (currentUser: FirestoreUser) => {
            const user = await userRepository.getUser(currentUser.uid)

            const finished = [...(user?.onboarding?.finished || [])]
            const skipped = [...(user?.onboarding?.skipped || [])]

            finished.push(OnboardingStepsEnum.CREATE_ACCOUNT)

            const onboarding = {
                finished: uniq(finished),
                skipped,
            }

            const isOnboardingUpdated = isEqual(onboarding.finished, user?.onboarding?.finished)

            dispatch({ type: SET_ONBOARDING, payload: onboarding })

            if (isOnboardingUpdated) return

            await userRepository.upsertUser(currentUser.uid, {
                onboarding,
            })
        },
        [dispatch]
    )

    const initTutorials = useCallback(async (currentUser: FirestoreUser) => {
        const user = await userRepository.getUser(currentUser.uid)

        return getCompletedTutorials(user)
    }, [])

    return { initOnboarding, initTutorials }
}

export const getCompletedTutorials = (user: User) => {
    const completedTutorials = user?.tutorial?.completed || []

    const completed: Record<tutorials, boolean> = completedTutorials.reduce(
        (acc, tutorial) => {
            return { ...acc, [tutorial]: true }
        },
        {} as Record<tutorials, boolean>
    )

    return completed
}
