import { alpha, Box, Chip, ChipProps, SxProps, Theme, Typography } from "@mui/material"
import React, { Fragment, useEffect, useRef, useState } from "react"

const TAG_WIDTH_WITH_SCROLL_IN_PX = 28

interface Props extends ChipProps {
    tagNesting: string[]
    isMobile?: boolean
    size?: "small" | "medium"
    disabled?: boolean
    selected?: boolean
    onDelete?: (event: any) => void
    onClick?: (event: any) => void
    searchText?: string
}

export const NestedTagChip: React.FC<Props> = ({
    size = "small",
    isMobile = false,
    searchText,
    tagNesting,
    ...props
}) => {
    const [shouldWrap, setShouldWrap] = useState(false)
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (isMobile || !ref?.current || !ref?.current.parentElement) return
        if (
            props.onDelete &&
            ref.current.getBoundingClientRect().right + TAG_WIDTH_WITH_SCROLL_IN_PX >
                ref.current.parentElement.getBoundingClientRect().right
        ) {
            setShouldWrap(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {shouldWrap && <Box width={TAG_WIDTH_WITH_SCROLL_IN_PX} />}
            <Chip
                ref={ref}
                size={size}
                sx={
                    isMobile
                        ? styles.chip
                        : {
                              ...styles.chip,
                              "& .MuiChip-deleteIcon": {
                                  width: 0,
                                  transform: "scale(0)",
                                  transition: "all 0.2s ease",
                              },
                              "&:hover": {
                                  "& .MuiChip-deleteIcon": {
                                      width: 17,
                                      transform: "scale(1)",
                                  },
                              },
                          }
                }
                variant={props.selected ? "filled" : "outlined"}
                color={props.selected ? "secondary" : "default"}
                {...props}
                label={
                    <Box sx={styles.container}>
                        {tagNesting.map((tag, index) => {
                            const isLast = index === tagNesting.length - 1

                            return (
                                <Fragment key={index}>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            fontWeight: isLast ? 600 : 400,
                                            color: (theme) =>
                                                isLast
                                                    ? theme.palette.text.primary
                                                    : alpha(theme.palette.text.primary, 0.7),
                                        }}
                                    >
                                        {tag}
                                    </Typography>
                                    {!isLast && (
                                        <Box
                                            component="span"
                                            sx={{
                                                color: (theme) =>
                                                    alpha(theme.palette.text.primary, 0.2),
                                            }}
                                        >
                                            |
                                        </Box>
                                    )}
                                </Fragment>
                            )
                        })}
                    </Box>
                }
            />
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flexWrap: "wrap",
        columnGap: 1,
        rowGap: 0,
    },
    chip: {
        px: 0,
        py: 0.4,
    },
}
