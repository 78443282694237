import { Box, Fade, SxProps, Theme } from "@mui/material"
import {
    checkExtensionStatus,
    posthogService,
    tutorials,
    useIsMobile,
    useTabFocus,
} from "@recall/common"
import { DarkLayout } from "components/layouts/DarkLayout"
import { COMPLETE_TUTORIAL_EVENT } from "constants/events"
import { HOME_PATH } from "constants/routes"
import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { SET_ACTIVE_TUTORIAL, TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"
import { CreateExtensionCardStep } from "./components/CreateExtensionCardStep"
import { InstallExtensionStep } from "./components/InstallExtensionStep"
import { IntroductionStep } from "./components/IntroductionStep"
import { MobileStep } from "./components/MobileStep"
import { PinTheExtensionStep } from "./components/PinTheExtensionStep"

const FadeWrapper: React.FC<React.PropsWithChildren<{ in: boolean }>> = ({
    children,
    in: inProp,
}) => (
    <Fade unmountOnExit in={inProp} timeout={1000}>
        <Box>{inProp && <Box sx={styles.step}>{children}</Box>}</Box>
    </Fade>
)

const InstallExtensionPage = () => {
    const [activeStep, setActiveStep] = useState<
        "mobile" | "introduction" | "install" | "pin" | "summarize"
    >("introduction")

    const history = useHistory()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    useEffect(() => {
        if (isMobile) setActiveStep("mobile")
    }, [isMobile])

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_TUTORIAL, payload: tutorials.EXTENSION })
        getExtensionStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getExtensionStatus = useCallback(async () => {
        const { isExtensionInstalled } = await checkExtensionStatus()

        if (
            !isMobile &&
            isExtensionInstalled &&
            (activeStep === "install" || activeStep === "introduction")
        )
            setActiveStep("pin")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep])

    useEffect(() => {
        getExtensionStatus()
    }, [getExtensionStatus])

    useTabFocus(getExtensionStatus)

    const handleSkip = () => {
        posthogService.captureEvent(COMPLETE_TUTORIAL_EVENT, {
            tutorialName: tutorials.EXTENSION,
            skipped: true,
            step: activeStep,
        })
        dispatch({ type: TUTORIAL_COMPLETE, payload: tutorials.EXTENSION })
        dispatch({ type: SET_ACTIVE_TUTORIAL, payload: "none" })
        history.push(HOME_PATH)
    }

    return (
        <DarkLayout isContained>
            <FadeWrapper in={activeStep === "mobile"}>
                <MobileStep handleContinue={() => handleSkip()} />
            </FadeWrapper>
            <FadeWrapper in={activeStep === "introduction"}>
                <IntroductionStep handleContinue={() => setActiveStep("install")} />
            </FadeWrapper>
            <FadeWrapper in={activeStep === "install"}>
                <InstallExtensionStep handleSkip={handleSkip} />
            </FadeWrapper>
            <FadeWrapper in={activeStep === "pin"}>
                <PinTheExtensionStep handleContinue={() => setActiveStep("summarize")} />
            </FadeWrapper>
            <FadeWrapper in={activeStep === "summarize"}>
                <CreateExtensionCardStep handleSkip={handleSkip} />
            </FadeWrapper>
        </DarkLayout>
    )
}

export default InstallExtensionPage

const styles: Record<string, SxProps<Theme>> = {
    step: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        py: 2,
        px: 3,
        minHeight: 400,
        width: { xs: "100%", md: "700px" },
    },
}
