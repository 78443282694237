import { Grid, Slide, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Spinner } from "./Spinner"

export const SummaryLoading = () => {
    const [loadingText, setLoadingText] = useState<string>("Loading")

    const loadingTexts: string[] = [
        "Parsing data",
        "Summarizing content",
        "Condensing ideas",
        "Extracting key points",
        "Discovering connections",
        "Finding main insights",
        "Generating summary",
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            const nextIndex = Math.floor(Math.random() * loadingTexts.length)
            setLoadingText(loadingTexts[nextIndex])
        }, 2000)

        return () => clearInterval(interval)
    }, [])

    return (
        <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item>
                <Spinner />
            </Grid>
            <Grid item overflow="hidden">
                <Slide direction="up" in timeout={200} key={loadingText}>
                    <Typography variant="h6" color="text.secondary">
                        {loadingText}
                    </Typography>
                </Slide>
            </Grid>
        </Grid>
    )
}
