import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { date, field, readonly, relation, writer } from "@nozbe/watermelondb/decorators"
import { ITEM_REVIEWS, ITEMS } from "../schema"
import { ItemModel } from "./ItemModel"

export class ItemReviewModel extends Model {
    static table = ITEM_REVIEWS

    static associations = {
        items: { type: "belongs_to", key: "item_id" } as BelongsToAssociation,
    }

    @field("is_saved") isSaved: boolean
    @field("reviews_count") reviewsCount: number
    @field("interest") interest: "never" | "soon" | "someday" | null

    @date("last_time_reviewed") lastTimeReviewed: Date
    @date("next_review_time") nextReviewTime: Date | null

    @relation(ITEMS, "item_id") item: Relation<ItemModel>

    @readonly @date("created_at") createdAt: Date
    @readonly @date("updated_at") updatedAt: Date

    isItemReviewed = () => {
        return (
            (this.nextReviewTime && this.nextReviewTime > new Date()) || this.interest === "never"
        )
    }

    prepareSave = () => {
        if (this.isSaved === false) {
            return this.prepareUpdate((record: ItemReviewModel) => {
                record.isSaved = true
            })
        }

        return null
    }

    prepareDelete = () => {
        if (this._preparedState === null) {
            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete() {
        const task = this.prepareDelete()
        await this.batch(task)
    }
}
