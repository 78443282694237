import { ChevronRight } from "@mui/icons-material"
import { Box, Collapse, SxProps, Theme, Typography } from "@mui/material"
import { useIsMobile } from "@recall/common"
import { useOpenItem } from "hooks/items/useOpenItem"
import { memo, useCallback, useState } from "react"
import { useLocation, useParams } from "react-router"
import { TagsTreeCard } from "./TagsTreeCard"
import { UntaggedItemsLoader } from "./UntaggedItemsLoader"
import { useUntaggedItems } from "./hooks/useUntaggedItems"

interface Props {
    isExpanded: boolean
    setIsExpanded: (isExpanded: boolean) => void
    searchText: string
    handleClickItem: (id: string) => void
}

const VISIBLE_ITEMS_STEP = 25

export const TagsTreeUntaggedItems = memo(
    ({ isExpanded, setIsExpanded, searchText, handleClickItem }: Props) => {
        const isMobile = useIsMobile()
        const location = useLocation()
        const [visibleItems, setVisibleItems] = useState(VISIBLE_ITEMS_STEP)
        const { id } = useParams<{ id: string }>()
        const { getItemPath } = useOpenItem()
        const { untaggedItems } = useUntaggedItems({ searchText })

        const handleObserve = useCallback(() => {
            setVisibleItems((prev) => prev + VISIBLE_ITEMS_STEP)
        }, [])

        if (!untaggedItems.length) return null

        return (
            <Box mt={0.5} mb={2}>
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? (
                        <ChevronRight
                            sx={{
                                ...styles.collapseIcon,
                                fontSize: isMobile ? "24px !important" : "18px",
                                ml: isMobile ? "-4px" : "-1px",
                            }}
                        />
                    ) : (
                        <ChevronRight
                            sx={{
                                ...styles.expandIcon,
                                fontSize: isMobile ? "24px !important" : "18px",
                                ml: isMobile ? "-4px" : "-1px",
                            }}
                        />
                    )}
                    <Typography
                        variant="caption"
                        ml={1}
                        textAlign="center"
                        display="flex"
                        alignItems="center"
                        sx={{ fontStyle: "italic" }}
                    >
                        Untagged cards
                    </Typography>
                </Box>
                <Collapse in={isExpanded}>
                    <Box ml={2.4} mt={0.2}>
                        {untaggedItems.slice(0, visibleItems).map((searchedItem) => (
                            <TagsTreeCard
                                key={searchedItem.item.id}
                                selected={
                                    id === searchedItem.item.id &&
                                    location.pathname.includes(getItemPath(id))
                                }
                                searchedItem={searchedItem}
                                searchText={searchText}
                                handleClick={handleClickItem}
                            />
                        ))}
                    </Box>
                    {untaggedItems.length && untaggedItems.length > visibleItems && (
                        <UntaggedItemsLoader handleLoadMore={handleObserve} />
                    )}
                </Collapse>
            </Box>
        )
    }
)

const styles: Record<string, SxProps<Theme>> = {
    expandIcon: {
        opacity: 0.6,
        color: (theme) => theme.palette.grey[500],
        transition: "transform 0.1s linear",
        "&:hover": {
            opacity: 1,
        },
    },
    collapseIcon: {
        opacity: 0.6,
        color: (theme) => theme.palette.grey[500],
        transform: "rotate(90deg)",
        transition: "transform 0.1s linear",
        "&:hover": {
            opacity: 1,
        },
    },
}
