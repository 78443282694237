import CircleIcon from "@mui/icons-material/Circle"
import { Theme, Tooltip, Typography } from "@mui/material"
import React from "react"

interface Props {
    isReference: boolean
    id?: string
}

export const SavedBadge: React.FC<React.PropsWithChildren<Props>> = (props) => {
    let description = "Saved card"
    let color: "success" | "secondary" = "success"

    if (props.isReference) {
        description = "Unpinned card"
        color = "secondary"
    }

    return (
        <Tooltip title={<Typography variant="body2">{description}</Typography>}>
            <CircleIcon
                sx={{
                    minWidth: "24px",
                    fontSize: "0.9rem",
                    verticalAlign: "text-top",
                    color: (theme: Theme) => theme.palette[color].main,
                }}
            />
        </Tooltip>
    )
}

export default SavedBadge
