import { Database, Q } from "@nozbe/watermelondb"
import { v4 as uuid } from "uuid"
import { deleteFromStorage } from "../../../editor/plugins/image"
import { AssetModel, ItemModel } from "../models"
import { ASSETS } from "../schema"

interface AssetPayload {
    item: ItemModel
    url: string
    size: number
    type: string
}

const createAsset = async (db: Database, payload: AssetPayload) => {
    await db.write(async () => {
        await db.collections.get<AssetModel>(ASSETS).create((record) => {
            record._raw.id = uuid()
            record.item.set(payload.item)
            record.url = payload.url
            record.size = payload.size
            record.type = payload.type
            record.isSaved = true
        })
    })
}

const getByUrlAndItemId = async (db: Database, url: string, itemId: string) => {
    return await db.collections
        .get<AssetModel>(ASSETS)
        .query(Q.and(Q.where("url", url), Q.where("item_id", itemId)))
        .fetch()
}

const deleteStale = async (db: Database) => {
    const assets = await db.collections.get<AssetModel>(ASSETS).query().fetch()

    for (const asset of assets) {
        try {
            await asset.item.fetch()
        } catch (error) {
            await deleteFromStorage(asset.url)
            await asset.delete()
        }
    }
}

const deleteByItemId = async (db: Database, itemId: string) => {
    const assets = await db.collections
        .get<AssetModel>(ASSETS)
        .query(Q.where("item_id", itemId))
        .fetch()

    for (const asset of assets) {
        await deleteFromStorage(asset.url)
        await asset.delete()
    }
}

export const assetRepository = { createAsset, deleteStale, deleteByItemId, getByUrlAndItemId }
