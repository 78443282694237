import CloseIcon from "@mui/icons-material/Close"
import ModeIcon from "@mui/icons-material/Mode"
import { alpha, Box, SxProps, Theme } from "@mui/material"
import { findNodePath, focusEditor, PlateElement, PlateElementProps } from "@udecode/plate-common"
import React, { MouseEvent, useEffect, useState } from "react"
import { Range, Transforms } from "slate"
import { ActionButton } from "../../components/buttons/ActionButton"
import { ItemPreviewPopper, Options } from "../../components/item/ItemPreviewPopper"
import { useHoverDelay } from "../../hooks/useHoverDelay"
import { useIsMobile } from "../../hooks/useIsMobile"
import { MentionItem } from "../../services/links/mentionsService"
import { RECALL_APP_URL } from "../../settings"
import { MyReferenceElement, useMyEditorState } from "../types"

export const REFERENCE_DIV_ID = "id-reference"

export interface ReferenceItem {
    id: string
    name: string
    description: string
    image: string
    mentionsCount: number
    mentions: MentionItem[]
}

interface ReferenceElementProps extends PlateElementProps<MyReferenceElement[]> {
    readOnly: boolean
    getItemByConnectionId: (id: string) => Promise<ReferenceItem>
    openItemById: (id: string, options?: Options) => void
    deleteConnectionById: (id: string) => Promise<void>
}

export const ReferenceElement: React.FC<React.PropsWithChildren<ReferenceElementProps>> = ({
    children,
    element,
    readOnly,
    openItemById,
    getItemByConnectionId,
    deleteConnectionById,
    ...props
}) => {
    const [item, setItem] = useState<ReferenceItem | null>(null)

    const isMobile = useIsMobile()
    const editor = useMyEditorState()

    const isCollapsed = Boolean(editor.selection && Range.isCollapsed(editor.selection))
    const canShowPopper = editor.selection === null || isCollapsed

    const { isOpen, setIsOpen, handleMouseEnter, handleMouseLeave } = useHoverDelay(300)

    useEffect(() => {
        getConnectionItem()
    }, [element.connectionId])

    const getConnectionItem = async () => {
        const item = await getItemByConnectionId(element.connectionId)
        if (!item) return
        setItem(item)
    }

    const handleDeleteConnection = async () => {
        await deleteConnectionById(element.connectionId)
    }

    const handleClickEdit = () => {
        setIsOpen(false)
        const text = element.children?.[0]?.text

        if (text) {
            const path = findNodePath(editor, element)

            if (path) {
                path.push(0)
                focusEditor(editor)
                Transforms.select(editor as any, { path: path, offset: text.length })
            }
        }
    }

    const handleClick = (event: MouseEvent) => {
        if (!item) return

        if (event.metaKey || event.ctrlKey || event.button === 1) {
            window.open(`${RECALL_APP_URL}/item/${item.id}`, "_blank")
        }
    }

    const actionButtonsStart = (
        <ActionButton onClick={handleClickEdit} tooltip={"Edit text"}>
            <ModeIcon fontSize={"inherit"} />
        </ActionButton>
    )

    const actionButtonsEnd = (
        <ActionButton onClick={() => setIsOpen(false)} tooltip={"Close"}>
            <CloseIcon fontSize={"inherit"} />
        </ActionButton>
    )

    return (
        <PlateElement
            id={REFERENCE_DIV_ID}
            as="span"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            element={element}
            {...props}
        >
            <Box component="span" id={element.editorBlockId + element.connectionId}>
                <ItemPreviewPopper
                    deleteConnection={handleDeleteConnection}
                    openItemById={openItemById}
                    item={item}
                    actionButtonsStart={isMobile ? actionButtonsStart : undefined}
                    actionButtonsEnd={isMobile ? actionButtonsEnd : undefined}
                    open={isOpen && canShowPopper && Boolean(item)}
                    readOnly={readOnly}
                >
                    <Box
                        component="a"
                        href={item ? `/item/${item.id}` : undefined}
                        sx={styles.wrapper}
                        onClick={handleClick}
                    >
                        {children}
                    </Box>
                </ItemPreviewPopper>
            </Box>
        </PlateElement>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    wrapper: {
        cursor: "pointer",
        outline: "none",
        textDecoration: "none",
        color: (theme) => (theme.palette.mode === "dark" ? "#f5d471" : "#3366cc") + "!important",
        "&:hover": {
            color: (theme) => alpha(theme.palette.mode === "dark" ? "#f5d471" : "#3366cc", 0.8),
        },
        "& ::selection": {
            color: (theme) => (theme.palette.mode === "dark" ? "primary.dark" : undefined),
        },
    },
}
