const CONTACT_US_EMAIL = "support@getrecall.ai"
let BASE_API_URL = "https://backend-l7hhbpzx5a-uc.a.run.app"
let EXTENSION_ID = "ldbooahljamnocpaahaidnmlgfklbben"
let RECALL_APP_URL = "https://app.getrecall.ai"
const RECALL_WEBSITE_URL = "https://getrecall.ai"
const RECALL_PRICING_PAGE = "https://app.getrecall.ai/pricing"
const RECALL_EXTENSION_STORE_URL =
    "https://chrome.google.com/webstore/detail/recall-web-extension/ldbooahljamnocpaahaidnmlgfklbben"
const FIREFOX_RECALL_EXTENSION_STORE_URL =
    "https://addons.mozilla.org/en-US/firefox/addon/getrecall/"

// BASE_API_URL = "http://localhost:8000"
// RECALL_APP_URL = "http://localhost:3000"

export {
    BASE_API_URL,
    CONTACT_US_EMAIL,
    EXTENSION_ID,
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    RECALL_APP_URL,
    RECALL_EXTENSION_STORE_URL,
    RECALL_PRICING_PAGE,
    RECALL_WEBSITE_URL,
}
