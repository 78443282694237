import { sentry } from "../sentry"

export const hasError = (status: number, statusText: string, url: string): boolean => {
    if (status < 200 || status >= 300) {
        if (status >= 500) {
            sentry.captureMessage(`Status: ${status} - ${statusText} - ${url}`)
        }
        return true
    }

    return false
}

export const buildUrl = (url: string, params: Record<string, string>): string => {
    const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, value]) => value !== null)
    )

    if (Object.keys(filteredParams).length !== 0) {
        url = url + "?" + new URLSearchParams(filteredParams).toString()
    }

    return url
}

export const buildPath = (path: string, url: string) => {
    return `${path}?url=${url}`
}
