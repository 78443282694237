import { alpha, Box } from "@mui/material"
import { PlateElement, PlateElementProps } from "@udecode/plate-common"
import { FC } from "react"
import { OPEN_URL } from "../../services/messaging/messageTypes"
import { formatYoutubeTimestamp, sentry } from "../../utils"
import { MyYouTubeTimestampElement } from "../types"

type Props = PlateElementProps<MyYouTubeTimestampElement[]> & { isExtension: boolean }

export const YouTubeTimestampElement: FC<Props> = ({
    className,
    children,
    isExtension,
    ...props
}) => {
    const openInNewTab = (url: string) => {
        if (window.isNative)
            // @ts-ignore
            window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: OPEN_URL, data: url }))
        else window.open(url, "_blank")
    }

    const value = props.element?.children?.[0]?.text

    if (!value) return null

    const formattedTimestamp = formatYoutubeTimestamp(value)

    const handleClick = () => {
        if (isExtension) {
            handleExtensionClick()
        } else {
            const url = props.element.url
            openInNewTab(url)
        }
    }

    const handleExtensionClick = () => {
        const video = document.querySelector("video")
        const [hours, minutes, seconds] = value.slice(1, -1).split(":").map(Number)

        const totalSeconds = seconds + minutes * 60 + hours * 3600

        if (video) {
            video.currentTime = totalSeconds

            if (video.paused) video.play()
        } else {
            sentry.captureMessage("Video not found")
        }
    }

    return (
        <PlateElement asChild {...props}>
            <Box
                component={"span"}
                onClick={handleClick}
                contentEditable={false}
                sx={{
                    gap: 0.5,
                    cursor: "pointer",
                    color: (theme) => alpha(theme.palette.text.primary, 0.5),
                    fontStyle: "italic",
                    textDecoration: "none",
                    "&:hover": {
                        textDecoration: "underline",
                        color: "#3366ccee",
                    },
                    fontSize: 14,
                    fontWeight: "normal",
                }}
            >
                {children}
                {formattedTimestamp}
            </Box>
        </PlateElement>
    )
}
