import { Add, Close } from "@mui/icons-material"
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Input,
    Radio,
    RadioGroup,
    SxProps,
    Theme,
    Typography,
} from "@mui/material"
import { getTheme, QuestionModel, reviewsScheduleRepository } from "@recall/common"
import { map, uniq } from "lodash"
import { ChangeEvent, FC, memo, useCallback, useEffect } from "react"
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form"
import { DeleteQuestion } from "./DeleteQuestion"

interface FormValues {
    options: { text: string }[]
    correctAnswer: string
    question: string
}

export type FormQuestion = Omit<FormValues, "options"> & { options: string[] }

interface Props {
    question?: QuestionModel
    handleClose: () => void
    handleFormSubmit: (question: QuestionModel, data: FormQuestion) => Promise<void>
    deleteQuestion: (question: QuestionModel) => Promise<void>
}

const defaultValues = {
    options: [{ text: "Answer 1" }, { text: "Answer 2" }],
    correctAnswer: "Answer 1",
    question: "",
}

export const QuestionFormComponent: FC<Props> = ({
    handleClose,
    handleFormSubmit,
    question,
    deleteQuestion,
}) => {
    const methods = useForm<FormValues>({
        defaultValues: question
            ? {
                  options: question.options.map((text) => ({ text })),
                  correctAnswer: question.correctAnswer,
                  question: question.question,
              }
            : defaultValues,
        mode: "onChange",
        reValidateMode: "onChange",
    })
    const {
        control,
        setError,
        handleSubmit,
        reset,
        formState: { isDirty, isValid },
        watch,
    } = methods

    const isCreateForm = !question

    const values = watch()

    useEffect(() => {
        if (isCreateForm) return

        if (isDirty && isValid) {
            handleSubmit(onSubmit)()
        }
    }, [JSON.stringify(values)]) // eslint-disable-line react-hooks/exhaustive-deps

    const validate = useCallback((data: FormValues) => {
        const isEveryOptionFilled = data.options.every(({ text }) => text)

        if (!isEveryOptionFilled) {
            setError("options", { message: "Every answer must be filled" })
            return false
        }

        const answers = map(data.options, "text")
        const areOptionsDuplicated = uniq(answers).length !== data.options.length

        if (areOptionsDuplicated) {
            setError("options", { message: "Every answer must be unique" })
            return false
        }

        if (data.options.length < 2) {
            setError("options", { message: "Minimum 2 answers are required" })
            return false
        }

        if (data.options.every(({ text }) => text !== data.correctAnswer)) {
            setError("options", { message: "Select correct answer" })
            return false
        }

        return true
        // eslint-disable-next-line
    }, [])

    const onSubmit = useCallback(async (data: FormValues) => {
        const isValid = validate(data)

        if (!isValid) return

        const answers = map(data.options, "text")

        await handleFormSubmit(question, { ...data, options: answers })

        if (isCreateForm) {
            handleClose()
            reset()
        } else {
            if (data.question !== question.question) {
                reviewsScheduleRepository.update({ id: question.id, question: data.question })
            }
        }

        // eslint-disable-next-line
    }, [])

    return (
        <FormProvider {...methods}>
            <Box>
                <Box mt={1} display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Controller
                        name="question"
                        control={control}
                        rules={{ required: "Question is required" }}
                        render={({ field, fieldState: { error } }) => (
                            <Box flex={1}>
                                <Input
                                    disableUnderline
                                    multiline
                                    fullWidth
                                    {...field}
                                    disabled={false}
                                    placeholder="Question you want to ask?"
                                />
                                {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </Box>
                        )}
                    />
                    {!isCreateForm && (
                        <DeleteQuestion deleteQuestion={deleteQuestion} question={question} />
                    )}
                </Box>
                <Box>
                    <QuestionOptions />
                </Box>
                {isCreateForm && (
                    <Button
                        sx={styles.submit}
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Create
                    </Button>
                )}
            </Box>
        </FormProvider>
    )
}

const QuestionOptions = () => {
    const {
        control,
        formState: { errors },
        watch,
        setValue,
        clearErrors,
    } = useFormContext<FormValues>()

    const {
        fields: options,
        update,
        append,
        remove,
    } = useFieldArray({
        control,
        name: "options",
    })

    const correctAnswer = watch("correctAnswer")

    const makeHandleOptionChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value
        if (options[index].text === correctAnswer) setValue("correctAnswer", text)

        update(index, { text })

        clearErrors("options")
    }

    const handleAddEmptyOption = () => {
        append({ text: "" })
        clearErrors("options")
    }

    const makeHandleRemoveOption = (index: number) => () => {
        remove(index)
        clearErrors("options")
    }

    return (
        <Controller
            name="correctAnswer"
            control={control}
            render={({ field }) => (
                <RadioGroup
                    {...field}
                    onChange={(...props) => {
                        clearErrors("options")
                        field.onChange(...props)
                    }}
                >
                    {options.map(({ text }, index) => (
                        <Box sx={styles.option} key={index}>
                            <FormControlLabel
                                sx={{ flex: 1, width: "100%" }}
                                value={text}
                                disabled={false}
                                control={<Radio color="secondary" />}
                                componentsProps={{ typography: { sx: { width: "100%" } } }}
                                label={
                                    <FormControl sx={{ flex: 1, width: "100%" }}>
                                        <Input
                                            sx={{ flex: 1 }}
                                            fullWidth
                                            multiline
                                            disableUnderline
                                            value={text}
                                            onChange={makeHandleOptionChange(index)}
                                            placeholder="Answer"
                                        />
                                    </FormControl>
                                }
                            />
                            <IconButton onClick={makeHandleRemoveOption(index)}>
                                <Close />
                            </IconButton>
                        </Box>
                    ))}
                    {errors?.options?.message && (
                        <FormHelperText error>{errors?.options?.message}</FormHelperText>
                    )}

                    <Typography sx={styles.addOption} onClick={handleAddEmptyOption}>
                        <Add />
                        Add option
                    </Typography>
                </RadioGroup>
            )}
        />
    )
}

const theme = getTheme("dark")

const styles: Record<string, SxProps<Theme>> = {
    submit: {
        mt: 2,
    },
    addOption: {
        display: "flex",
        alignItems: "center",
        my: 1,
        gap: 0.5,
        color: (theme) => theme.palette.grey["500"],
        cursor: "pointer",

        "&:hover": {
            color: "inherit",
        },
    },
    option: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        opacity: 0.8,
        gap: 1,
        [theme.breakpoints.up("md")]: {
            "& button": {
                opacity: 0,
            },
        },

        "&:hover": {
            opacity: 1,
            "& button": {
                opacity: 1,
            },
        },
    },
}

export const QuestionForm = memo(QuestionFormComponent)
