// @ts-nocheck
import { ELEMENT_MENTION_INPUT } from "@udecode/plate-mention"
import { Editor, Point, Range, Element as SlateElement, Transforms } from "slate"

import { SEARCH_REFERENCE_TYPE } from "../../services/editorData/constants"
import { EditorBlockData } from "../../services/editorData/EditorBlockData"
import { MyEditor } from "../types"

const CustomEditor = {
    isInlineActive(editor: any) {
        const [match] = Editor.nodes(editor, {
            match: (n) => editor.isInline(n) === true,
        })
        return !!match
    },

    toggleMark(editor: any, format: string) {
        const isActive = this.isMarkActive(editor, format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    },

    isMarkActive(editor: any, format: string) {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    },

    isActive(editor: any, format: string) {
        const [match] = Editor.nodes(editor, {
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
        })
        return !!match
    },

    isReferenceTypeActive(editor: MyEditor) {
        return this.isTypeActive(editor, "reference")
    },

    isSearchReferenceTypeActive(editor: MyEditor) {
        return this.isTypeActive(editor, SEARCH_REFERENCE_TYPE)
    },

    isLinkTypeActive(editor: MyEditor) {
        return this.isTypeActive(editor, "link")
    },

    isTypeActive(editor: any, type: string) {
        const [match] = Editor.nodes(editor, {
            match: (n) => n.type === type,
        })
        return !!match
    },

    getTextNode(editor) {
        const path = editor.selection?.anchor?.path

        if (!path) {
            return
        }

        let textNode = editor
        for (let index of path) {
            textNode = textNode.children[index]
        }

        return textNode
    },

    matchConnectionId(editor: any, connectionId: string) {
        const matches = Editor.nodes(editor, {
            match: (node: any, path) => node.connectionId === connectionId,
            at: [],
        })
        return matches
    },

    insertSearchReference(editor: any, openedWithForwardSlash: boolean) {
        const { selection } = editor
        const isCollapsed = selection && Range.isCollapsed(selection)

        if (!isCollapsed) {
            return
        }

        const searchReference = {
            trigger: openedWithForwardSlash ? "/" : "",
            type: ELEMENT_MENTION_INPUT,
            children: [{ text: "" }],
        }

        if (!this.isInlineActive(editor)) {
            Transforms.insertNodes(editor, searchReference)
        }
    },
    insertSearchReferenceText(editor: any, text = "") {
        const { selection } = editor
        const isCollapsed = selection && Range.isCollapsed(selection)

        if (isCollapsed) {
            return
        }

        const searchReference = {
            trigger: "",
            type: ELEMENT_MENTION_INPUT,
            children: [{ text }],
        }

        if (!this.isInlineActive(editor)) {
            Transforms.insertNodes(editor, searchReference)
        }
    },
    removeSearchReference(editor: MyEditor) {
        // @ts-ignore
        Transforms.removeNodes(editor, { at: [], match: (n) => n.type === ELEMENT_MENTION_INPUT })
    },

    insertReference(editor: any, connectionId: string, value: string) {
        const { selection } = editor
        const isCollapsed = selection && Range.isCollapsed(selection)

        this.removeSearchReference(editor)

        if (!isCollapsed) {
            return
        }
        const reference = {
            type: "reference",
            connectionId: connectionId,
            children: [{ text: value }],
        }
        if (this.isInlineActive(editor)) {
            const focus = editor.selection?.focus

            if (!focus) {
                return
            }
            const nextPoint = Editor.after(editor, focus)
            Transforms.insertNodes(editor, reference, { at: nextPoint })
            return
        }

        Transforms.insertNodes(editor, reference)
    },

    enter(editor: any) {
        if (this.isInlineActive(editor)) {
            return
        }

        const { selection } = editor
        const after = Editor.after(editor, selection.focus)
        const before = Editor.before(editor, selection.focus)

        if (after === undefined || selection.focus.path[0] !== after.path[0]) {
            const detailBlock = EditorBlockData.create()
            Transforms.insertNodes(editor, detailBlock, { mode: "highest" })
            return
        }

        if (before === undefined || selection.focus.path[0] !== before.path[0]) {
            const detailBlock = EditorBlockData.create()
            Transforms.insertNodes(editor, detailBlock, { mode: "highest" })
            return
        }

        Transforms.splitNodes(editor, { mode: "highest", voids: true })
        return
    },

    unwrapLink(editor: any) {
        Transforms.unwrapNodes(editor, {
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
        })
    },

    wrapLink(editor: any, url: string) {
        if (this.isActive(editor, "link")) {
            this.unwrapLink(editor)
        }

        const { selection } = editor
        const isCollapsed = selection && Range.isCollapsed(selection)
        const link = {
            type: "link",
            url,
            children: isCollapsed ? [{ text: url }] : [],
        }

        if (isCollapsed) {
            Transforms.insertNodes(editor, link)
        } else {
            Transforms.wrapNodes(editor, link, { split: true })
            Transforms.collapse(editor, { edge: "end" })
        }
    },
    moveCursorAfterReference(editor: any) {
        // Set the selection to the end of the inserted reference
        // WARNING: This is fragile and will break if the schema changes.
        const nextAnchor = Editor.after(editor, editor.selection.anchor.path)

        if (Point.isPoint(nextAnchor)) {
            Transforms.select(editor, {
                anchor: nextAnchor,
                focus: nextAnchor,
            })
        }
    },
    isSelectionCollapsed(editor: MyEditor) {
        const { selection } = editor
        const isCollapsed = selection && Range.isCollapsed(selection)
        return isCollapsed
    },
}

export default CustomEditor
