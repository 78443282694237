import {
    ClientRectObject,
    getDefaultBoundingClientRect,
    useVirtualFloating,
} from "@udecode/plate-floating"
import { FloatingToolbarState } from "@udecode/plate-floating/dist"

import {
    getSelectionText,
    isSelectionExpanded,
    mergeProps,
    useEventEditorSelectors,
    usePlateEditorState,
} from "@udecode/plate-common"
import { useState } from "react"
import { useFocused } from "slate-react"
import { useIsMobile } from "../../hooks/useIsMobile"

export const getCustomSelectionBoundingClientRect = (domRange: Range): ClientRectObject => {
    return domRange.getBoundingClientRect()
}

export const useFloatingToolbarState = ({
    floatingOptions,
    hideToolbar,
    ignoreReadOnly,
    isExtension = false,
}: FloatingToolbarState & { isExtension?: boolean }) => {
    const editor = usePlateEditorState()
    const focusedEditorId = useEventEditorSelectors.focus()
    const focused = useFocused()
    const isMobile = useIsMobile()

    const [menuState, setMenuState] = useState<{
        isOpen: boolean
        getBoundingClientRect: () => ClientRectObject
        range: Range | null
    }>({
        isOpen: false,
        getBoundingClientRect: () => getSelectionBoundingClientRect(isExtension),
        range: null,
    })
    const [open, setOpen] = useState(false)
    const [waitForCollapsedSelection, setWaitForCollapsedSelection] = useState(false)

    const selectionExpanded = editor && isSelectionExpanded(editor)
    const selectionText = editor && getSelectionText(editor)

    const floating = useVirtualFloating(
        mergeProps(
            {
                getBoundingClientRect: () => getSelectionBoundingClientRect(isExtension),
                open,
                onOpenChange: setOpen,
            },
            floatingOptions
        )
    )

    const handleSetMenuOpen = (isOpen: boolean) => {
        if (!isMobile) return

        if (isOpen) {
            const selection = window.getSelection()
            if (!selection) return

            const range = selection.getRangeAt(0).cloneRange()

            setMenuState({
                getBoundingClientRect: () => getCustomSelectionBoundingClientRect(range),
                isOpen,
                range,
            })
        } else {
            setMenuState((state) => ({
                ...state,
                getBoundingClientRect: () => getSelectionBoundingClientRect(isExtension),
                isOpen,
            }))
        }
    }

    return {
        isMenuOpen: menuState.isOpen,
        setIsMenuOpen: handleSetMenuOpen,
        editor,
        open,
        setOpen,
        waitForCollapsedSelection,
        setWaitForCollapsedSelection,
        selectionExpanded,
        selectionText,
        focused,
        focusedEditorId,
        ignoreReadOnly,
        hideToolbar,
        floating,
    }
}

export const getSelectionBoundingClientRect = (isExtension: boolean): ClientRectObject => {
    const domSelection = getSelection(isExtension)

    if (!domSelection || domSelection.rangeCount < 1) {
        return getDefaultBoundingClientRect()
    }

    const domRange = domSelection.getRangeAt(0)

    return domRange.getBoundingClientRect()
}

export const getSelection = (isExtension?: boolean) => {
    if (isExtension) {
        const sectionElement = document.getElementById("id-recall-extension-root")
        return getShadowSelection(sectionElement?.shadowRoot)
    }
    return window.getSelection()
}

const getShadowSelection = (shadowRoot: any) => {
    if (shadowRoot && typeof shadowRoot.getSelection === "function") {
        return shadowRoot.getSelection()
    }
    return null
}
