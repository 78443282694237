import { pick } from "lodash"
import { ConnectionModel, EditorBlockModel } from "../../storage/watermelon"
import { EditorBlockData } from "../editorData"

interface TextBlock {
    text: string
    isHighlighted?: boolean
    connectionId?: string
}

interface MentionSentence {
    textBlocks: TextBlock[]
    elementId: string
}

export interface MentionItem {
    id: string
    name: string
    description: string
    image: string
    blocks: MentionSentence[]
}

const getByConnections = async (connections: ConnectionModel[]): Promise<MentionItem[]> => {
    const items: MentionItem[] = []
    for (const connection of connections) {
        const item = await connection.from
        const editorBlocks = await item.editorBlocks.fetch()
        const blocks = getMentionedEditorBlocks(editorBlocks, connection.id)

        items.push({
            ...pick(item, ["id", "image", "name", "description"]),
            blocks,
        })
    }

    return items
}

const getMentionedEditorBlocks = (editorBlocks: EditorBlockModel[], connectionId: string) => {
    const mentionedEditorBlocks = editorBlocks.filter((editorBlock) =>
        JSON.stringify(editorBlock.children).includes(connectionId)
    )

    let blocks: MentionSentence[] = []
    for (const editorBlock of mentionedEditorBlocks) {
        const textElements = EditorBlockData.getTextElements([editorBlock])
        const elementBlocks = getMentionSentence(textElements, connectionId)
        blocks = [
            ...blocks,
            {
                textBlocks: elementBlocks,
                elementId: editorBlock.id + connectionId,
            },
        ]
    }

    return blocks
}

const getMentionSentence = (mentions: any, connectionId: string) => {
    const texts = []
    let accumulator = ""
    let isAdded = false

    for (const mention of mentions) {
        if (mention?.connectionId === connectionId) {
            isAdded = true
            if (accumulator) {
                texts.push({ text: accumulator })
                accumulator = ""
            }

            texts.push({ ...mention, connectionId, isHighlighted: true })
        } else {
            accumulator += mention.text
            if (accumulator.length > 40) {
                if (!isAdded) {
                    accumulator = "..." + accumulator.slice(-40)
                } else {
                    accumulator = accumulator.slice(0, 40).trimEnd() + "..."
                    texts.push({ text: accumulator })
                    break
                }
            }
        }
    }
    return texts
}

export const mentionsService = { getByConnections }
