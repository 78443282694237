export const ImageBlockData = {
    getUrl(imageBlock: any) {
        return imageBlock.urlOriginal
    },

    getUrl320(imageBlock: any) {
        if (imageBlock.url_320) {
            return imageBlock.url_320
        } else {
            return this.getUrl(imageBlock)
        }
    },

    getUrl1024(imageBlock: any) {
        if (imageBlock.url_1024) {
            return imageBlock.url_1024
        } else {
            return this.getUrl(imageBlock)
        }
    },

    getUrlThumbnail(imageBlock: any) {
        if (imageBlock.urlThumbnail) {
            return imageBlock.urlThumbnail
        } else {
            return this.getUrl(imageBlock)
        }
    },
}
