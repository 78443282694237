import { alpha, Box, SxProps, Theme, Tooltip, Typography } from "@mui/material"
import { FC } from "react"
import { PremiumIcon } from "../icons/PremiumIcon"
import { EXTENSION_USAGE_LIMIT } from "../../services/usage/summaryUsageService"
import { RECALL_APP_URL } from "../../settings"
import { posthogService } from "../../services/postHog"

interface Props {
    usage: number | undefined
    trackingEventName: string
}

export const UsageChip: FC<Props> = ({ usage, trackingEventName }) => {
    const handleUpgrade = () => {
        window.open(`${RECALL_APP_URL}/pricing`, "_blank")
        posthogService.captureEvent(trackingEventName)
    }

    if (usage === undefined) return null

    return (
        <Tooltip title="This is how many cards you can create before you need to upgrade.">
            <Box sx={styles.count} onClick={handleUpgrade}>
                <PremiumIcon sx={{ fontSize: "1.3rem", marginTop: -0.3 }} />
                <Typography variant="caption" gap={0.3} display="flex">
                    <span>{Math.min(usage, EXTENSION_USAGE_LIMIT)}</span>/
                    <span>{EXTENSION_USAGE_LIMIT}</span>
                </Typography>
            </Box>
        </Tooltip>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    count: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.6,
        color: (theme) => theme.palette.text.secondary,
        border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.2)}`,
        borderRadius: 1,
        px: 1,
        py: 0.5,
        height: "100%",
        cursor: "pointer",

        "&:hover": {
            background: (theme) => theme.palette.action.hover,
        },
    },
}
