import { editableProps } from "./config"

import { Plate } from "@udecode/plate-common"
import { FC, useEffect, useState } from "react"

import { Box, SxProps, Theme } from "@mui/material"
import { isEqual } from "lodash"
import { v4 as uuid } from "uuid"
import { isFirefox } from "../utils"
import { PlateConfig } from "./PlateConfig"
import { PlateSkeleton } from "./components/PlateSkeleton"
import { FixedToolbar } from "./components/Toolbar/FixedToolbar"
import { FloatingToolbar } from "./components/Toolbar/FloatingToolbar"
import { RERENDER_EDITOR_BLOCKS_EVENT } from "./constants"
import { EditorElements, MyPlatePlugin } from "./types"

interface Props {
    id: string
    readOnly?: boolean
    plugins: MyPlatePlugin[]
    isExtension?: boolean
    sx?: SxProps<Theme>
    initEditorBlocks: () => Promise<EditorElements>
    updateEditorBlocks: (_: EditorElements) => Promise<void>
    cleanUpCopiedBlocks: (blocks: EditorElements, depth?: number) => Promise<EditorElements>
    uploadImage: (arrayBuffer: ArrayBuffer, contentType: string) => Promise<string>
}

export const PlateEditor: FC<Props> = ({
    id,
    readOnly = false,
    plugins,
    sx = {},
    isExtension = false,
    initEditorBlocks,
    updateEditorBlocks,
    cleanUpCopiedBlocks,
    uploadImage,
}) => {
    const [editorBlocks, setEditorBlocks] = useState<EditorElements | null>(null)
    // Force rerenders editor, after image deletion for e.g., changing editorBlock don't cause rerender so this is hacky solution
    const [plateEditorKey, setPlateEditorKey] = useState(uuid())

    const getEditorBlocks = async () => {
        const editorBlocks = await initEditorBlocks()
        setEditorBlocks(editorBlocks)
        setPlateEditorKey(uuid())
    }

    useEffect(() => {
        getEditorBlocks()

        document.addEventListener(RERENDER_EDITOR_BLOCKS_EVENT, getEditorBlocks)

        return () => {
            document.removeEventListener(RERENDER_EDITOR_BLOCKS_EVENT, getEditorBlocks)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const update = async (newValue: EditorElements) => {
        await updateEditorBlocks(newValue)
        setEditorBlocks(newValue)
    }

    const handleChange = async (newValue: EditorElements) => {
        if (isEqual(editorBlocks, newValue)) return
        const cleanedUpValue = await cleanUpCopiedBlocks(newValue)
        await update(cleanedUpValue)
    }

    if (!editorBlocks) return <PlateSkeleton />

    return (
        <Box sx={[styles.editor, ...(Array.isArray(sx) ? sx : [sx])]} translate="no">
            <Plate<EditorElements>
                key={plateEditorKey}
                plugins={plugins}
                initialValue={editorBlocks}
                value={editorBlocks}
                onChange={handleChange}
                editableProps={{
                    ...editableProps,
                    readOnly: (isExtension && isFirefox()) || readOnly,
                    className: "plate-editor",
                }}
            >
                <PlateConfig />
                <FloatingToolbar isExtension={isExtension} />
                {!isExtension && !readOnly && <FixedToolbar uploadImage={uploadImage} />}
            </Plate>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    editor: {
        backgroundColor: "background.default",
        borderRadius: 1,
        p: 0,
        pb: 2,
        maxWidth: "100%",
        wordBreak: "break-word",
        position: "relative",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        ".plate-editor": {
            "h1, h2, h3, h4, h5, h6": {
                lineHeight: 1.3,
                mt: 2.5,
                mb: 1.25,
                ":first-child": {
                    mt: 0.5,
                },
            },
        },
    },
}
