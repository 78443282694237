import { Avatar, Chip, Tooltip, Typography } from "@mui/material"
import React from "react"
import { Image } from "../../types"
import { TotalCountBadge } from "../badges/TotalCountBadge"

interface Props {
    name: string
    description?: string
    image?: Image
    hideTooltip?: boolean
    mentionCount?: number | null
    onlyOtherMentionCount?: boolean
    onClick?: (e: any) => void
    disabled?: boolean
}

export const CardChip: React.FC<Props> = ({
    name,
    description,
    image,
    mentionCount,
    onClick,
    hideTooltip,
    onlyOtherMentionCount = true,
    disabled = false,
}) => {
    let label = <>{name}</>

    if (!hideTooltip) {
        label = (
            <Tooltip
                title={
                    <>
                        <Typography variant="body2">{name}</Typography>
                        {description || ""}
                    </>
                }
                arrow
            >
                <span>{name}</span>
            </Tooltip>
        )
    }

    return (
        <Chip
            disabled={disabled}
            onClick={onClick ? onClick : undefined}
            sx={{
                mr: 1,
                mb: 1,
                bgcolor: (theme) => theme.palette.background.layout,
                maxWidth: "250px",
            }}
            label={label}
            avatar={
                image ? (
                    <Avatar
                        sx={{ bgcolor: (theme) => theme.palette.text.secondary }}
                        alt={
                            image?.caption && Array.isArray(image.caption)
                                ? image.caption[0]?.text || image.caption[0]
                                : image?.caption || ""
                        }
                        src={image?.urlThumbnail || image?.url}
                    />
                ) : undefined
            }
            variant="outlined"
            deleteIcon={
                mentionCount ? (
                    <TotalCountBadge
                        name={name}
                        count={mentionCount}
                        onlyOtherMentionCount={onlyOtherMentionCount}
                    />
                ) : undefined
            }
            onDelete={mentionCount ? () => {} : undefined}
        />
    )
}
