import { Property } from "./Property"

export interface Connection {
    id: string
    toId: string
    fromId: string
    property?: Property
}

export const dispatchConnectionDeleted = (connectionId: string) => {
    if (typeof document === "undefined") return

    const event = new CustomEvent("connectionDeleted", { detail: { connectionId } })
    document.dispatchEvent(event)
}
