import { MenuItem, Select } from "@mui/material"
import { FC } from "react"
import { Controller, useFormContext } from "react-hook-form"

export const defaultLanguageOption = {
    label: "English",
    value: "en",
}

export interface Option {
    label: string
    value: string
    flag: string
}

interface Props {
    options: Option[]
    name?: string
}

export const LanguageSelect: FC<Props> = ({ options, name = "language" }) => {
    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name={name}
            rules={{ required: "Language is required" }}
            render={({ field: { onChange, value } }) => {
                return (
                    <Select
                        sx={{ minWidth: 300, mt: 1 }}
                        placeholder="Language"
                        onChange={onChange}
                        value={value}
                    >
                        {options.map(({ value, label, flag }) => (
                            <MenuItem value={value} key={value}>
                                {flag} &nbsp; {label}
                            </MenuItem>
                        ))}
                    </Select>
                )
            }}
        />
    )
}
