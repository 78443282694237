import { HttpClient } from "../utils"

const QUESTIONS_PATH = "/questions/"

export class QuestionsApi {
    httpClient: HttpClient

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient
    }

    async generateQuestions(text: string, existingQuestions?: string[], headers?: HeadersInit) {
        try {
            const response = await this.httpClient.post(
                QUESTIONS_PATH,
                JSON.stringify({
                    text,
                    model: "gpt-4",
                    existing_questions: existingQuestions || null,
                }),
                headers
            )
            return await response.json()
        } catch {
            return null
        }
    }
}
