import { Button, Grid, Snackbar, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { itemRepository } from "@recall/common"
import { ItemView } from "components/shared/ItemPartial/ItemView"
import { isEmpty } from "lodash"
import { Fragment, useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { RERENDER_ITEMS_LIST, useGroupedItems } from "../hooks/useGroupedItems"
import { useScrollPosition } from "../hooks/useScrollPosition"
import { ItemsListEmpty } from "./ItemsListEmpty"
import { ItemsLoader } from "./ItemsLoader"
import { NewUserMessage } from "./NewUserMessage"
import { Link } from "react-router-dom"

export const ItemsList = () => {
    const db = useDatabase()
    const selectedTagIds = useSelector((state: RootState) => state.drawer.selectedTagIds)
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set())

    const view = useSelector((state: RootState) => state.items.view)
    const isDrawerOpen = useSelector((state: RootState) => state.drawer.open)

    const isFilterApplied = Boolean(selectedTagIds.length)

    const { groupedItems, order, itemsCount } = useGroupedItems()

    const items = Object.values(groupedItems).flat()
    const isEmptyList = isFilterApplied && items.length === 0

    useScrollPosition(!isEmpty(groupedItems))

    const toggleSelected = useCallback((id: string) => {
        setSelectedItems((prev) => {
            const prevSet = new Set(prev)
            const isSelected = prevSet.has(id)

            if (isSelected) prevSet.delete(id)
            else prevSet.add(id)
            return prevSet
        })
    }, [])

    const handleDeleteItems = async () => {
        const selected = await itemRepository.getByIds(db, Array.from(selectedItems))

        for (const item of selected) {
            await item.delete()
        }

        setSelectedItems(new Set())
        document.dispatchEvent(new CustomEvent(RERENDER_ITEMS_LIST))
    }

    useEffect(() => {
        const deselectAll = () => {
            setSelectedItems(new Set())
        }
        document.body.addEventListener("click", deselectAll)

        return () => {
            document.body.removeEventListener("click", deselectAll)
        }
    }, [])

    if (isEmptyList) return <ItemsListEmpty />

    return (
        <>
            {order.map((date, index) => (
                <Fragment key={date}>
                    <Grid item xs={12} pt={index === 0 ? "0 !important" : 1}>
                        <Typography pt={index === 0 ? 0 : 1} fontSize={14}>
                            {date}
                        </Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                        {groupedItems[date].map((item) => (
                            <Grid
                                xs={12}
                                sm={isDrawerOpen ? 12 : 6}
                                md={isDrawerOpen ? 6 : 4}
                                lg={view === "grid" ? 3 : 4}
                                xl={3}
                                item
                                key={item.id}
                                component={Link}
                                sx={{ textDecoration: "none" }}
                                to={`/item/${item.id}`}
                            >
                                <ItemView
                                    isSelected={selectedItems.has(item.id)}
                                    toggleSelected={toggleSelected}
                                    isSelectionActive={Boolean(selectedItems.size)}
                                    item={item}
                                    view={view}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Fragment>
            ))}
            {Boolean(order.length) && (
                <ItemsLoader itemsCount={itemsCount} isVisible={Boolean(items.length)} />
            )}
            {!isFilterApplied && <NewUserMessage />}
            {Boolean(selectedItems.size) && (
                <Snackbar
                    sx={{ mx: 2 }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open
                    message={`${selectedItems.size} Selected`}
                    action={
                        <Button
                            onClick={handleDeleteItems}
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === "light" ? "secondary.main" : undefined,
                            }}
                        >
                            Delete
                        </Button>
                    }
                />
            )}
        </>
    )
}
