import { useState } from "react"

export const useRerender = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setState] = useState(0)

    const rerender = () => {
        setState((prev) => prev + 1)
    }

    return rerender
}
