import { alpha, Avatar, Chip, Grid, SxProps, Theme } from "@mui/material"
import { FC, useCallback } from "react"
import { assets } from "../assets"

interface Example {
    url: string
    title: string
    icon: string
}

const examples: Example[] = [
    {
        url: "https://www.youtube.com/watch?v=hFL6qRIJZ_Y",
        title: "Podcast",
        icon: assets.PODCAST_ICON.url,
    },
    {
        url: "https://www.youtube.com/watch?v=Jt7hE12n11s",
        title: "Youtube Video",
        icon: assets.VIDEO_ICON.url,
    },
    {
        url: "https://www.nature.com/articles/d41586-023-02296-z",
        title: "Blog Post",
        icon: assets.BLOG_ICON.url,
    },
    {
        url: "https://www.nytimes.com/2023/02/17/business/china-chatgpt-microsoft-openai.html",
        title: "News Article",
        icon: assets.NEWS_ICON.url,
    },
    {
        url: "https://en.wikipedia.org/wiki/Hedonic_treadmill",
        title: "Wikipedia page",
        icon: assets.WIKIPEDIA_ICON.url,
    },
]

export const ExampleLinks: FC<{ small?: boolean }> = ({ small = true }) => {
    const makeHandleClick = useCallback(
        (url: string) => async () => {
            if (window) {
                // @ts-ignore
                window.open(url, "_blank").focus()
                return
            }
            document.location.href = url
        },
        []
    )

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={1.5}>
            {examples.map((exampleLink) => (
                <Grid item key={exampleLink.title}>
                    <Chip
                        avatar={<Avatar alt={exampleLink.title} src={exampleLink.icon} />}
                        onClick={makeHandleClick(exampleLink.url)}
                        label={exampleLink.title}
                        sx={styles.chip}
                        size={small ? "small" : undefined}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    chip: {
        color: alpha("#000000", 0.8),
        fontWeight: 500,
        backgroundColor: alpha("#FFFFFF", 0.3),
        "&:hover": {
            backgroundColor: alpha("#FFFFFF", 0.5),
        },
    },
}
