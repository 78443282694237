import { IconButton, Menu, Tooltip } from "@mui/material"
import { IconMoodSmile } from "@tabler/icons-react"
import { useEmojiDropdownMenuState } from "@udecode/plate-emoji"
import { useRef } from "react"
import { emojiCategoryIcons, emojiSearchIcons } from "./EmojiIcons"
import { EmojiPicker } from "./EmojiPicker"

export const EmojiDropDownMenu = () => {
    const { isOpen, setIsOpen, emojiPickerState } = useEmojiDropdownMenuState()
    const anchorEl = useRef<HTMLButtonElement | null>(null)

    return (
        <>
            <Tooltip title="Emoji">
                <IconButton
                    onClick={() => {
                        setIsOpen(true)
                    }}
                    ref={anchorEl}
                    size={"small"}
                    sx={{
                        color: (theme) =>
                            isOpen ? theme.palette.text.primary : theme.palette.action.active,
                        backgroundColor: (theme) =>
                            isOpen ? theme.palette.action.selected : undefined,
                        ":hover": {
                            backgroundColor: (theme) =>
                                isOpen ? theme.palette.action.selected : undefined,
                        },
                    }}
                >
                    <IconMoodSmile />
                </IconButton>
            </Tooltip>
            <Menu
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                MenuListProps={{
                    sx: { p: 0.5, px: 1 },
                }}
                anchorEl={anchorEl.current}
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <EmojiPicker
                    {...emojiPickerState}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    icons={{
                        categories: emojiCategoryIcons,
                        search: emojiSearchIcons,
                    }}
                />
            </Menu>
        </>
    )
}
