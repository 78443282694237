import { Database } from "@nozbe/watermelondb"
import { createPluginFactory } from "@udecode/plate-common"
import {
    deleteObject,
    getBytes,
    getDownloadURL,
    getMetadata,
    ref,
    uploadBytes,
    uploadString,
} from "firebase/storage"
import { toast } from "react-toastify"
import { v4 as uuidv4 } from "uuid"
import { firebase } from "../../services"

import { ASSET_TYPES } from "../../storage/watermelon/models/AssetModel"
import { ItemModel } from "../../storage/watermelon/models/ItemModel"
import { assetRepository } from "../../storage/watermelon/repository/assetRepository"
import { sentry } from "../../utils"
import { MAX_IMAGE_SIZE_IN_BYTES } from "../constants/assets"

export const ELEMENT_CUSTOM_IMAGE = "image"

export const createCustomImagePlugin = createPluginFactory({
    key: ELEMENT_CUSTOM_IMAGE,
    isElement: true,
    isVoid: true,
})

const getContentType = (base64Image: string): string => {
    const match = base64Image.match(/^data:(image\/[a-zA-Z0-9+.-]+);base64,/)
    if (match && match[1]) {
        return match[1]
    }
    return "image/jpeg"
}

export const uploadBase64Image = async (
    base64Image: string,
    uid: string
): Promise<string | null> => {
    try {
        const contentType = getContentType(base64Image)
        const storageRef = ref(firebase.storage, `user/${uid}/card-images/${uuidv4()}`)
        const snapshot = await uploadString(storageRef, base64Image, "data_url", { contentType })
        const downloadURL = await getDownloadURL(snapshot.ref)
        return downloadURL
    } catch (error: any) {
        sentry.captureException(error)
        return null
    }
}

export const uploadArrayBufferImage = async (
    arrayBuffer: ArrayBuffer,
    contentType: string,
    uid: string
): Promise<string | null> => {
    try {
        const storageRef = ref(firebase.storage, `user/${uid}/card-images/${uuidv4()}`)
        const metadata = {
            contentType: contentType,
        }
        const snapshot = await uploadBytes(storageRef, arrayBuffer, metadata)
        const downloadURL = await getDownloadURL(snapshot.ref)
        return downloadURL
    } catch (error: any) {
        sentry.captureException(error)
        return null
    }
}

const getPathFromUrl = (url: string): string | null => {
    const baseUrlIndex = url.indexOf("/o/")
    if (baseUrlIndex === -1) {
        return null
    }
    const pathPart = url.substring(baseUrlIndex + 3, url.indexOf("?"))
    return decodeURIComponent(pathPart)
}

export const deleteFromStorage = async (url: string) => {
    const path = getPathFromUrl(url)
    if (!path) return

    const imageRef = ref(firebase.storage, path)
    try {
        await deleteObject(imageRef)
    } catch (error: any) {
        sentry.captureException(error)
    }
}

export const uploadImageToStorage = async (data: string | ArrayBuffer, uid: string) => {
    if (typeof data === "string") {
        return await uploadBase64Image(data, uid)
    }

    return null
}

export const getBase64Size = (base64String: string): number => {
    const base64WithoutHeader = base64String.split(",")[1]
    const padding = (base64WithoutHeader.match(/=+$/) || [])[0]?.length || 0
    const base64Length = base64WithoutHeader.length * 0.75 - padding
    return Math.round(base64Length)
}

export const detectMimeType = (arrayBuffer: ArrayBuffer): string => {
    const uint8Array = new Uint8Array(arrayBuffer)

    if (uint8Array[0] === 0xff && uint8Array[1] === 0xd8 && uint8Array[2] === 0xff) {
        return "image/jpeg"
    } else if (
        uint8Array[0] === 0x89 &&
        uint8Array[1] === 0x50 &&
        uint8Array[2] === 0x4e &&
        uint8Array[3] === 0x47
    ) {
        return "image/png"
    } else if (uint8Array[0] === 0x47 && uint8Array[1] === 0x49 && uint8Array[2] === 0x46) {
        return "image/gif"
    } else if (uint8Array[0] === 0x42 && uint8Array[1] === 0x4d) {
        return "image/bmp"
    } else if (
        uint8Array[0] === 0x49 &&
        uint8Array[1] === 0x49 &&
        uint8Array[2] === 0x2a &&
        uint8Array[3] === 0x00
    ) {
        return "image/tiff"
    } else if (
        uint8Array[0] === 0x4d &&
        uint8Array[1] === 0x4d &&
        uint8Array[2] === 0x00 &&
        uint8Array[3] === 0x2a
    ) {
        return "image/tiff"
    } else if (
        uint8Array[0] === 0x3c &&
        uint8Array[1] === 0x3f &&
        uint8Array[2] === 0x78 &&
        uint8Array[3] === 0x6d &&
        uint8Array[4] === 0x6c
    ) {
        return "image/svg+xml"
    } else if (
        uint8Array[0] === 0x00 &&
        uint8Array[1] === 0x00 &&
        uint8Array[2] === 0x01 &&
        uint8Array[3] === 0x00
    ) {
        return "image/x-icon"
    } else {
        return "application/octet-stream"
    }
}

export async function duplicateImage(srcUrl: string, uid: string) {
    try {
        const destPath = `user/${uid}/card-images/${uuidv4()}`
        const srcRef = ref(firebase.storage, srcUrl)
        const destRef = ref(firebase.storage, destPath)
        const fileData = await getBytes(srcRef)
        const metadata = await getMetadata(srcRef)
        await uploadBytes(destRef, fileData, metadata)
        const downloadURL = await getDownloadURL(destRef)
        return { url: downloadURL, size: fileData.byteLength }
    } catch (error: any) {
        sentry.captureException(error)
        return { url: null, size: 0 }
    }
}

export const makeUploadImage =
    (db: Database, item: ItemModel, uid: string) =>
    async (dataUrl: string | ArrayBuffer): Promise<any> => {
        if (typeof dataUrl !== "string") return null
        const size = getBase64Size(dataUrl)

        if (size > MAX_IMAGE_SIZE_IN_BYTES) {
            toast.error(`Max image size is ${MAX_IMAGE_SIZE_IN_BYTES / (1024 * 1024)}MB`)
            return null
        }

        const url = await uploadImageToStorage(dataUrl, uid)
        if (!url) return null

        await assetRepository.createAsset(db, { item, url, size, type: ASSET_TYPES.IMAGE })
        if (!item.image) {
            await item.updateImage(url)
        }
        return url
    }
