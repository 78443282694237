import { Model } from "@nozbe/watermelondb"
import { field } from "@nozbe/watermelondb/decorators"
import { CONNECTION_PROPERTIES } from "../schema"

export class ConnectionPropertyModel extends Model {
    static table = CONNECTION_PROPERTIES

    @field("name") name: string
    @field("display") display: string
    @field("description") description: string
    @field("plural_display") pluralDisplay: string
    @field("wikidata_pid") wikidataPid: string
    @field("is_saved") isSaved: boolean

    prepareSave = () => {
        if (this.isSaved === false)
            return this.prepareUpdate((record: ConnectionPropertyModel) => {
                record.isSaved = true
            })

        return null
    }
}
