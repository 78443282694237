import { Database, Q } from "@nozbe/watermelondb"
import { v4 as uuidv4 } from "uuid"
import { Property } from "../../../types"
import { ConnectionPropertyModel } from "../models"
import { CONNECTION_PROPERTIES } from "../schema"

const getOrCreate = async (db: Database, property: Property): Promise<ConnectionPropertyModel> => {
    const collection = db.collections.get<ConnectionPropertyModel>(CONNECTION_PROPERTIES)

    const connectionPropertyModels = await collection.query(Q.where("name", property.name)).fetch()

    if (connectionPropertyModels.length) return connectionPropertyModels[0]

    return await collection.create((record: ConnectionPropertyModel) => {
        record._raw.id = uuidv4()
        record.name = property.name
        record.display = property.display
        record.description = property.description || record.description
        record.wikidataPid = property.wikidataPid || record.wikidataPid
        record.isSaved = true
    })
}

const getByIds = (db: Database, propertyIds: string[]) => {
    return db.collections
        .get<ConnectionPropertyModel>(CONNECTION_PROPERTIES)
        .query(Q.where("id", Q.oneOf(propertyIds)))
}

export const connectionPropertyRepository = { getOrCreate, getByIds }
