import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { field, relation, writer } from "@nozbe/watermelondb/decorators"
import { dispatchConnectionDeleted } from "../../../types"
import { CONNECTION_PROPERTIES, CONNECTIONS } from "../schema"
import { ConnectionPropertyModel } from "./ConnectionPropertyModel"
import { ItemModel } from "./ItemModel"

export class ConnectionModel extends Model {
    static table = CONNECTIONS

    static associations = {
        property: { type: "belongs_to", key: "property_id" } as BelongsToAssociation,
    }

    @field("is_saved") isSaved: boolean
    @relation("items", "from_id") from: Relation<ItemModel>
    @relation("items", "to_id") to: Relation<ItemModel>
    @relation(CONNECTION_PROPERTIES, "property_id") property: Relation<ConnectionPropertyModel>

    prepareSave = () => {
        if (this.isSaved === false)
            return this.prepareUpdate((record: ConnectionModel) => {
                record.isSaved = true
            })

        return null
    }

    @writer async deleteWithStaleItem(dispatchEvent = true) {
        const tasks = await this.prepareDeleteWithStaleItem(dispatchEvent)
        await this.batch(...tasks)
    }

    prepareDeleteWithStaleItem = async (dispatchEvent = true) => {
        let tasks: any[] = []

        const task = this.prepareDelete(dispatchEvent)

        try {
            const toItem = await this.to.fetch()
            const count = await toItem.mentions.count
            const isStale = await toItem.isStale()

            if (isStale && count <= 1) tasks = await toItem.prepareDelete()
        } catch {}

        tasks.push(task)
        return tasks
    }

    prepareDelete = (dispatchEvent = true) => {
        if (this._preparedState === null) {
            if (dispatchEvent === true) {
                dispatchConnectionDeleted(this.id)
            }

            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete(dispatchEvent = true) {
        const task = this.prepareDelete(dispatchEvent)

        if (!task) return

        await this.batch(task)
    }
}
