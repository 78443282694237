import { useMediaQuery, useTheme } from "@mui/material"

export const useIsMobile = () => {
    const theme = useTheme()

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
        matchMedia: (query) => window.matchMedia(query),
    })

    return isMobile
}
