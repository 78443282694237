import { disposableEmails } from "constants/disposableEmails"

// https://github.com/jquense/yup/blob/2a9e060594423018f517419ef5d2f10e417c9fbd/src/string.ts#L20 v1.4.0
export const EMAIL_VALIDATION_REGEX =
    // eslint-disable-next-line
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const emailValidation = {
    required: "Email is required.",
    pattern: {
        value: EMAIL_VALIDATION_REGEX,
        message: "Invalid email address.",
    },
    validate: {
        notDisposable: (email: string) => {
            if (!email) return true
            const domain = email.split("@")[1]
            return !disposableEmails.includes(domain) || "Disposable emails are not allowed."
        },
    },
}
export const passwordValidationWithoutMinLength = {
    required: "Password is required.",
}

export const passwordValidation = {
    ...passwordValidationWithoutMinLength,
    minLength: {
        value: 8,
        message: "Password is too short - should be 8 chars minimum.",
    },
}

export const passwordConfirmationValidation = (watchPassword: () => string) => ({
    required: "Password confirmation is required.",
    validate: (value: string) => value === watchPassword() || "Passwords do not match.",
})
