import { Article, Hub } from "@mui/icons-material"
import { Box, Grid, IconButton, Skeleton, SxProps, Theme, Tooltip } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    IS_EXTENSION_TUTORIAL_CARD,
    ItemModel,
    itemRepository,
    tutorials,
    useIsMobile,
} from "@recall/common"
import KnowledgeGraphPage from "components/KnowledgeGraphPage/KnowledgeGraphPage"
import { Header } from "components/layouts/components/Header/Header"
import { HOME_PATH } from "constants/routes"
import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { useQueryParameter } from "hooks/useQueryParameter"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router"
import { TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"
import { Breadcrumbs } from "./components/breadcrumbs/Breadcrumbs"
import { Item } from "./components/item/Item"

export const ELEMENT_ID = "elementId"

const ItemPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useDispatch()
    const db = useDatabase()
    const history = useHistory()
    const location = useLocation<{ elementId?: string }>()
    const { id } = useParams<{ id: string }>()
    const [item, setItem] = useState<ItemModel | null>(null)
    const { param, isPresent, remove } = useQueryParameter("focusedElementId")
    const [focusedElementId, setFocusedElementId] = useState(location?.state?.elementId)

    const [isGraphView, setIsGraphView] = useState(false)
    const contentRef = useRef<HTMLDivElement>()
    const isMobile = useIsMobile()
    const { resetBreadcrumbs } = useBreadcrumbActions()

    const fetchItem = async () => {
        const item = await itemRepository.get(db, id)
        if (!item) return history.push(HOME_PATH)

        setItem(item)
    }

    useEffect(() => {
        const focusedElementId = location?.state?.elementId || param
        setFocusedElementId(focusedElementId)
        if (isPresent) remove()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        fetchItem()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        const isExtensionTutorialCard = location.search.includes(IS_EXTENSION_TUTORIAL_CARD)

        if (isExtensionTutorialCard) {
            dispatch({ type: TUTORIAL_COMPLETE, payload: tutorials.EXTENSION })
        }

        return () => {
            resetBreadcrumbs(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggleGraphView = () => {
        setIsGraphView((prev) => !prev)
    }

    return (
        <Grid container spacing={0} justifyContent="center">
            <Grid item xs={12}>
                <Header />
            </Grid>
            {!item ? (
                <Grid item xs={12} lg={10} xl={8}>
                    <Skeleton variant="rectangular" height={900} />
                </Grid>
            ) : (
                <>
                    <Grid item xs={12} lg={10} xl={8}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Breadcrumbs />

                            {!isMobile && (
                                <IconButton onClick={toggleGraphView} sx={{ mb: 0.4 }}>
                                    {isGraphView ? (
                                        <Tooltip title="Card view">
                                            <Article />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Graph view">
                                            <Hub />
                                        </Tooltip>
                                    )}
                                </IconButton>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={10} xl={8} key={item.id} ref={contentRef}>
                        {isGraphView && contentRef.current ? (
                            <Box sx={styles.graphWrapper}>
                                <KnowledgeGraphPage
                                    width={contentRef?.current?.offsetWidth}
                                    height={window.innerHeight * 0.8}
                                    item={item}
                                />
                            </Box>
                        ) : (
                            <Item item={item} focussedConnection={focusedElementId} />
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export default ItemPage

const styles: Record<string, SxProps<Theme>> = {
    graphWrapper: {
        border: (theme) =>
            `1px solid ${
                theme.palette.mode === "dark"
                    ? theme.palette.background.paper
                    : theme.palette.text.primary
            }`,
        borderRadius: 1,
        position: "relative",
        overflow: "hidden",
    },
}
