import { Box, DialogContent, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { tutorials, useIsMobile } from "@recall/common"
import LogoDark from "assets/svg/logo_full_dark.svg"
import LogoLight from "assets/svg/logo_full_light.svg"
import { ITEM_PARTIAL_DIV_ID } from "components/shared/ItemPartial/ItemView"
import { useIsDarkMode } from "hooks/useThemeMode"
import React, { FC, memo, useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { itemHelper } from "storage/watermelon/helper/item"
import { Step, TutorialBase, commonTutorialStyles } from "./TutorialBase"

export const itemsIntroTutorialName = "itemsIntro"

export interface Props {
    goNext: Function
}

const KnowledgeCardExampleStep: FC<Props> = ({ goNext }) => {
    const isMobile = useIsMobile()

    useEffect(() => {
        const handleOnClick = () => {
            setTimeout(() => {
                goNext()
            })
        }

        const button = document.getElementById(ITEM_PARTIAL_DIV_ID)

        if (button) {
            button.addEventListener("click", handleOnClick)
            return () => button.removeEventListener("click", handleOnClick)
        }
    }, [goNext])

    return (
        <DialogContent sx={commonTutorialStyles.popoverContent}>
            <Typography sx={commonTutorialStyles.heading}>Knowledge card</Typography>
            <Typography sx={commonTutorialStyles.body}>This is a knowledge card.</Typography>
            <Typography sx={commonTutorialStyles.body}>
                <b>{isMobile ? "Press" : "Click"}</b> it to continue.
            </Typography>
        </DialogContent>
    )
}

const TutorialItemsIntroComponent: React.FC = () => {
    const isDarkMode = useIsDarkMode()

    const db = useDatabase()
    const uid = useSelector((state: RootState) => state.user.uid)

    useEffect(() => {
        itemHelper.initializeDefaultCard(db, uid)

        // eslint-disable-next-line
    }, [])

    const steps: Step[] = [
        {
            anchorId: null,
            placement: null,
            showArrow: false,
            component: () => (
                <DialogContent sx={commonTutorialStyles.dialogContent}>
                    <Box display="flex" justifyContent="center">
                        <Box
                            component="img"
                            alt="recall logo"
                            height={30}
                            src={isDarkMode ? LogoDark : LogoLight}
                        />
                    </Box>
                    <Typography
                        pt={1.5}
                        style={{ textAlign: "center", fontSize: 28 }}
                        variant={"h4"}
                    >
                        Welcome to Recall
                    </Typography>
                    <Typography pt={1.5} variant="body1">
                        Start building your knowledge base with AI-powered summaries and your own
                        content. Our summaries work best on{" "}
                        <strong>YouTube videos, PDFs, blogs, recipes, and articles</strong> — with
                        more on the way! (Podcasts, emails, and beyond are coming soon.)
                    </Typography>
                </DialogContent>
            ),
        },
        {
            anchorId: ITEM_PARTIAL_DIV_ID,
            placement: "bottom",
            showArrow: true,
            isNextDisabled: false,
            component: (props: Props) => <KnowledgeCardExampleStep {...props} />,
        },
    ]

    return <TutorialBase tutorialName={tutorials.ITEMS_INTRO} steps={steps} />
}

export const TutorialItemsIntro = memo(TutorialItemsIntroComponent)
