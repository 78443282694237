import { Avatar } from "@mui/material"
import React from "react"

interface Props {
    count: number
    color: "primary" | "secondary" | "default"
}

const CountBadge: React.FC<React.PropsWithChildren<Props>> = (props) => {
    return (
        <Avatar
            sizes={"small"}
            sx={{
                mr: "3px",
                width: (theme) => theme.spacing(3),
                height: (theme) => theme.spacing(3),
                fontSize: "1em",
                color: (theme) => theme.palette.text.secondary,
                backgroundColor: (theme) => theme.palette.action.disabledBackground,
            }}
        >
            {props.count}
        </Avatar>
    )
}

export default CountBadge
