import { Box, Skeleton } from "@mui/material"
import React from "react"

const LoadingSearchResult: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Box
            sx={{
                display: "flex",
                p: 1,
            }}
        >
            <Skeleton variant="circular" width={60} height={60} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                }}
            >
                <Skeleton height={30} width="60%" sx={styles} />
                <Skeleton height={10} width="80%" sx={styles} />
                <Skeleton height={10} width="85%" sx={styles} />
            </Box>
        </Box>
    )
}

const styles = { marginBottom: 0.5, marginLeft: 1, borderRadius: 0.5 }

export default LoadingSearchResult
