import { IUsageService, UsageService } from "./usageService"

export const EXTENSION_USAGE_LIMIT = 10

export class SummaryUsageService extends UsageService implements IUsageService {
    limit = EXTENSION_USAGE_LIMIT

    increaseUsage = async (userId: string) => {
        const summariesGeneratedCount = await this.getUsage(userId)
        const user = await this.userRepository.getUser(userId)

        const newSummariesGeneratedCount = summariesGeneratedCount + 1

        await this.userRepository.upsertUser(userId, {
            usage: this.getUserUsage(user, { extension: newSummariesGeneratedCount }),
        })

        return newSummariesGeneratedCount
    }

    isUsageExceeded = async (userId: string) => {
        const summariesGeneratedCount = await this.getUsage(userId)
        return summariesGeneratedCount >= this.limit
    }

    getUsage = async (userId: string) => {
        const user = await this.userRepository.getUser(userId)

        const summariesGeneratedCount = user?.usage?.extension || 0

        return summariesGeneratedCount
    }
}
