import DeleteIcon from "@mui/icons-material/Delete"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import { Box, SxProps, Theme, Tooltip } from "@mui/material"
import React, { ReactNode } from "react"
import { ReferenceItem } from "../../editor"
import { ActionButton } from "../buttons/ActionButton"
import { ItemPreview } from "./ItemPreview/ItemPreview"

export interface Options {
    state?: { elementId: string }
    reset?: boolean
    target?: string
}

interface Props {
    item: ReferenceItem | null
    children: React.ReactNode
    open: boolean
    actionButtonsStart?: ReactNode
    actionButtonsEnd?: ReactNode
    readOnly?: boolean
    disablePortal?: boolean
    openItemById: (id: string, options?: Options) => void
    deleteConnection: (_: string) => void
}

export const ItemPreviewPopper: React.FC<React.PropsWithChildren<Props>> = ({
    item,
    open,
    actionButtonsEnd,
    actionButtonsStart,
    children,
    readOnly,
    openItemById,
    deleteConnection,
    disablePortal = false,
}: Props) => {
    const handleOpenItem = () => {
        if (item) openItemById(item.id)
    }

    const handleDelete = () => {
        if (item) {
            deleteConnection(item.id)
        }
    }

    return (
        <Tooltip
            open={open}
            PopperProps={{
                sx: styles.popper,
                disablePortal,
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -2],
                        },
                    },
                ],
            }}
            title={
                open && (
                    <Box sx={styles.title}>
                        {!readOnly && (
                            <Box sx={styles.header}>
                                <Box>
                                    <ActionButton
                                        onClick={handleDelete}
                                        tooltip={"Delete link to this card"}
                                    >
                                        <DeleteIcon fontSize={"inherit"} />
                                    </ActionButton>
                                    {actionButtonsStart && actionButtonsStart}
                                </Box>
                                <Box>
                                    <ActionButton onClick={handleOpenItem} tooltip={"Open Card"}>
                                        <OpenInFullIcon fontSize={"inherit"} />
                                    </ActionButton>
                                    {actionButtonsEnd && actionButtonsEnd}
                                </Box>
                            </Box>
                        )}
                        {item && (
                            <ItemPreview
                                openItemById={(id, state) => openItemById(id, { state })}
                                item={item}
                                onClick={readOnly ? undefined : handleOpenItem}
                            />
                        )}
                    </Box>
                )
            }
            arrow
        >
            <span>{children}</span>
        </Tooltip>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    popper: {
        "& .MuiTooltip-arrow": {
            color: (theme: Theme) => theme.palette.background.layout,
        },
        "& .MuiTooltip-arrow::before": {
            border: (theme: Theme) => theme.borders.paper,
        },
        "& .MuiTooltip-tooltip": {
            minWidth: 250,
            maxWidth: 350,
            padding: 0,
            borderRadius: 1,
            border: (theme: Theme) => theme.borders.paper,
            backgroundColor: (theme: Theme) => theme.palette.background.layout,
        },
    },
    title: {
        boxShadow: 3,
        maxWidth: 350,
        minWidth: 200,
        overflow: "hidden",
        borderRadius: 1,
        borderTopLeftRadius: 0,
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
}
