import { deleteDoc, doc, getDoc, setDoc } from "firebase/firestore"
import { v5 as uuidv5 } from "uuid"
import { FIRESTORE_COLLECTIONS } from "../constants"
import { firebase } from "../services"
import { RECALL_APP_URL } from "../settings"

export interface SharedCard {
    userId: string
}

const SHARING_BASE_URL = RECALL_APP_URL + "/share"

const getSharingUrl = (itemId: string) => {
    const sharingId = getSharedIdByItemId(itemId)
    return `${SHARING_BASE_URL}/${sharingId}`
}

const getSharedIdByItemId = (itemId: string) => {
    const hash = uuidv5("shared-card", itemId)
    return hash
}

const shareCard = async (uid: string, itemId: string) => {
    await setDoc(
        doc(firebase.firestore, FIRESTORE_COLLECTIONS.SHARED_CARDS, getSharedIdByItemId(itemId)),
        {
            uid,
            itemId,
        }
    )
}

const unshareCard = async (itemId: string) => {
    await deleteDoc(
        doc(firebase.firestore, FIRESTORE_COLLECTIONS.SHARED_CARDS, getSharedIdByItemId(itemId))
    )
}

const getSharedItem = async (id: string) => {
    const snapshot = await getDoc(doc(firebase.firestore, FIRESTORE_COLLECTIONS.SHARED_CARDS, id))
    if (!snapshot.exists()) return null
    return snapshot.data()
}

export const sharingRepository = {
    getSharedItem,
    shareCard,
    unshareCard,
    getSharedIdByItemId,
    getSharingUrl,
}
