import { sentry } from "../../utils"

export const handleScroll = () => {
    try {
        const selection = window.getSelection()
        if (!selection) return

        const range = selection.getRangeAt(0)

        const screenHeight = window.innerHeight
        const yHeight = range.getClientRects()?.[0]?.y

        if (!yHeight) return
        let scrollAmount: boolean | number = false

        const heightFromBottom = screenHeight - yHeight

        if (heightFromBottom / screenHeight < 0.55) {
            scrollAmount = screenHeight * 0.6 - heightFromBottom
        }

        if (scrollAmount !== false) {
            window.scrollBy({
                top: scrollAmount,
                behavior: "smooth",
            })
        }
    } catch (e: any) {
        sentry.captureException(e)
        return
    }
}
