import axios from "axios"
import { hasError } from "./common"

const get = async (url: string, headers: HeadersInit = {}) => {
    try {
        const { data, status, statusText } = await axios.get(url, { headers })
        if (hasError(status, statusText, url)) return { data: null, error: data }
        return { data }
    } catch (e) {
        return { data: null, cost: null }
    }
}

export const axiosClient = { get }
