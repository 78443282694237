import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { field, json, relation, writer } from "@nozbe/watermelondb/decorators"
import { EDITOR_ORDERS } from "../schema"
import { ItemModel } from "./ItemModel"

const sanitizeEditorOrder = (data: any) => {
    return data
}

export class EditorOrderModel extends Model {
    static table = EDITOR_ORDERS

    static associations = {
        items: { type: "belongs_to", key: "item_id" } as BelongsToAssociation,
    }

    @field("is_saved") isSaved: boolean
    @relation("items", "item_id") item: Relation<ItemModel>
    @json("order", sanitizeEditorOrder) order: string[]

    prepareSave = () => {
        if (this.isSaved === false) {
            return this.prepareUpdate((record: EditorOrderModel) => {
                record.isSaved = true
            })
        }

        return null
    }

    prepareDelete = () => {
        if (this._preparedState === null) {
            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete() {
        const task = this.prepareDelete()
        await this.batch(task)
    }
}
