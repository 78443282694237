import { useState } from "react"
import { storageService } from "services/storageService"

export enum seenOptions {
    ONBOARDING_BANNER = "onboarding-banner",
    NOT_FOUND_ERROR = "not-found-error",
}

export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000
export const ONE_WEEK_IN_MS = 7 * ONE_DAY_IN_MS

export const useIsSeen = (target: seenOptions, milliseconds = ONE_WEEK_IN_MS) => {
    const [isSeen, setIsSeen] = useState(() => {
        const lastSeen = storageService.getItem(target)

        if (!lastSeen) return false

        const minDateToBeVisible = Date.now() - milliseconds

        return minDateToBeVisible <= Number(lastSeen)
    })

    const [isSeenOnce, setIsSeenOnce] = useState(() => {
        const lastSeen = storageService.getItem(target)
        if (!lastSeen) return false
        return true
    })

    const handleSetSeen = () => {
        storageService.setItem(target, Date.now())
        setIsSeen(true)
        setIsSeenOnce(true)
    }

    return { isSeen, isSeenOnce, handleSetSeen }
}
