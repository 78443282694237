import { Database, Model } from "@nozbe/watermelondb"
import { hasUnsyncedChanges } from "@nozbe/watermelondb/sync"
import { sentry } from "../../utils"
import { COLLECTIONS } from "./schema"

const isStatusSynced = (record: Model) => {
    return record.syncStatus === "synced"
}

const subscribeOnChanges = (db: Database, callback: Function) => {
    const subscription = db.withChangesForTables(COLLECTIONS).subscribe({
        next: async (event) => {
            if (!event) return
            if (event.every(({ record }) => isStatusSynced(record))) return

            callback()
        },
        error: (e) => sentry.captureException(e),
    })

    return subscription
}

const checkUnsyncedChanges = async (db: Database) => {
    return await hasUnsyncedChanges({ database: db })
}

const deleteDatabase = async (db: Database) => {
    await db.write(async () => {
        await db.unsafeResetDatabase()
    })
}

const escapeId = (id: string) => {
    const regex = /[.,$#[\]/"]/g
    return id.replace(regex, "-")
}

const sanitizeSearchTerm = (term: string) => {
    const specialChars = [
        "\\",
        "^",
        "$",
        "*",
        "+",
        "?",
        ".",
        "(",
        ")",
        "|",
        "{",
        "}",
        "[",
        "]",
        '"',
        "'",
    ]

    return term
        .split("")
        .map((char) => (specialChars.includes(char) ? `\\${char}` : char))
        .join("")
}

export const dbUtils = {
    escapeId,
    checkUnsyncedChanges,
    deleteDatabase,
    sanitizeSearchTerm,
    subscribeOnChanges,
}
