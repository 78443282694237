import { Database, Q } from "@nozbe/watermelondb"
import { subDays } from "date-fns"
import { v4 as uuid } from "uuid"
import { QuestionModel, QuestionReviewModel } from "../models"
import { QUESTION_REVIEWS } from "../schema"

const getAll = async (db: Database) => {
    return await db.collections.get<QuestionReviewModel>(QUESTION_REVIEWS).query().fetch()
}

const getReviewForPreviousPeriod = async (db: Database, period: number) => {
    const previousWeek = subDays(new Date(), period)
    return await db.collections
        .get<QuestionReviewModel>(QUESTION_REVIEWS)
        .query(Q.where("created_at", Q.gte(previousWeek.getTime())))
        .fetch()
}

const getByQuestionId = async (db: Database, questionId: string) => {
    return await db.collections
        .get<QuestionReviewModel>(QUESTION_REVIEWS)
        .query(Q.where("question_id", questionId))
        .fetch()
}

const create = async (
    db: Database,
    question: QuestionModel,
    questionReview: Pick<QuestionReviewModel, "isCorrect">
) => {
    return await db.write(async () => {
        const item = await question.item.fetch()
        const itemReview = await db.collections
            .get<QuestionReviewModel>(QUESTION_REVIEWS)
            .create((record) => {
                record._raw.id = uuid()
                record.item.set(item)
                record.reviewedQuestion.set(question)
                record.question = question.question
                record.options = question.options
                record.answer = question.answer
                record.correctAnswer = question.correctAnswer
                record.isCorrect = questionReview.isCorrect
                record.isSaved = true
            })

        return itemReview
    })
}

export const questionReviewRepository = {
    create,
    getAll,
    getByQuestionId,
    getReviewForPreviousPeriod,
}
