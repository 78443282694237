import { Box, Typography } from "@mui/material"
import { FC } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { SummaryLengthToggle } from "./SummaryLengthToggle"

export const SummaryLengthForm: FC = () => {
    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name="summaryLength"
            render={({ field: { onChange, value } }) => (
                <Box display="flex" flexDirection="column">
                    <Typography fontWeight={500} variant="h5">
                        Summary Length
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        Choose the default length for your summaries.
                    </Typography>
                    <Box mt={1}>
                        <SummaryLengthToggle value={value} onChange={onChange} />
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        {value === "concise"
                            ? "Concise summaries provide a brief overview of the main points of the content."
                            : "Detailed summaries offer a comprehensive breakdown of the content and aim to capture all important points."}
                    </Typography>
                </Box>
            )}
        />
    )
}
