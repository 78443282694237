import { ELEMENT_IMAGE } from "@udecode/plate-media"
import { MAX_DESCRIPTION_LENGTH } from "../../../constants/editor"
import { ELEMENT_CUSTOM_EDITOR_BLOCK } from "../../../editor/plugins/editor-block"
import { ELEMENT_CUSTOM_PARAGRAPH } from "../../../editor/plugins/paragraph"
import { YOUTUBE_TIMESTAMP } from "../../../editor/plugins/youtube-timestamp"
import { EditorBlockModel } from "../models/EditorBlockModel"

export const getFormattedText = (editorBlocks: any[]) => {
    const elements = getTextElements(editorBlocks)
    return elements.map(({ text }) => text).join("")
}

const getTextElements = (editorBlocks: any[], endsWithDot = false) => {
    let texts: { text: string; connectionId?: string }[] = []

    editorBlocks.forEach((child, index) => {
        if (child.connectionId) {
            let text = child?.children?.length ? getText(child.children, null) : ""
            text = !Boolean(text) && Boolean(child.text) ? child.text : text

            texts = [
                ...texts,
                {
                    text: endsWithDot && index === 0 ? " " + text : text,
                    connectionId: child.connectionId,
                },
            ]
        } else if (child.children) {
            const isLastEndOfSentence =
                texts.length > 0 ? texts[texts.length - 1]?.text?.endsWith(".") : false
            texts = [...texts, ...getTextElements(child.children, isLastEndOfSentence)]
        } else {
            texts = [...texts, { text: (endsWithDot && index === 0 ? " " : "") + child.text }]
        }
    })

    return texts
}

export const getText = (editorBlocks: any[], maxLength: number | null = MAX_DESCRIPTION_LENGTH) => {
    let text = ""

    for (let child of editorBlocks) {
        if (!child || child?.type === YOUTUBE_TIMESTAMP) continue

        if (child.children) {
            text += " " + getText(child.children, maxLength)
        } else {
            text += " " + child.text
        }
        if (maxLength && text.length > maxLength) {
            return text
        }
    }

    return text.trim()
}

export const getFirstImageBlock = (editorBlocks: any[]) => {
    for (let editorBlock of editorBlocks) {
        if (!editorBlock) continue

        if (editorBlock.type === "image" || editorBlock.type === "img") {
            return editorBlock
        }

        for (let child of editorBlock.children) {
            if (!child) continue

            if (child.type === "image" || child.type === "img") {
                return child
            }
        }
    }
}

export const isOnlyChildParagraph = (editorBlock: EditorBlockModel) => {
    return (
        // @ts-ignore
        editorBlock.children?.length === 1 &&
        editorBlock.children?.[0]?.type === ELEMENT_CUSTOM_PARAGRAPH
    )
}

export const toEditorBlock = (editorBlock: EditorBlockModel) => {
    let children = editorBlock?.children
    const isEditorBlockElement =
        !editorBlock.type || editorBlock.type === ELEMENT_CUSTOM_EDITOR_BLOCK

    if (isEditorBlockElement && isOnlyChildParagraph(editorBlock)) children = children[0].children

    return {
        id: editorBlock.id,
        children,
        type: editorBlock.type || ELEMENT_CUSTOM_EDITOR_BLOCK,
        options: editorBlock.options,
        ...(editorBlock.options || {}),
    }
}

export const removeEditorBlockWithUrl = (editorBlocks: any[], targetUrl: string) => {
    return editorBlocks
        .map((block) => {
            if (block.children) {
                block.children = removeEditorBlockWithUrl(block.children, targetUrl)
            }
            return block
        })
        .filter((block) => {
            const hasUrl =
                block.type === ELEMENT_IMAGE &&
                (block.url === targetUrl || block.options?.url === targetUrl)
            return !hasUrl
        })
}
