import { collection, getDocs, query, where } from "@firebase/firestore"
import { FIRESTORE_COLLECTIONS } from "../../constants"
import { firebase } from "./firebaseServices"

const LIFETIME_PRICE_ID = "price_1NiAlNKLKMKbaiD6re1deI2Y"

const checkUserSubscriptions = async (userId: string) => {
    const subscriptionsQuery = query(
        collection(
            firebase.firestore,
            FIRESTORE_COLLECTIONS.USERS,
            userId,
            FIRESTORE_COLLECTIONS.SUBSCRIPTIONS
        ),
        where("status", "==", "active")
    )

    const subscriptions = await getDocs(subscriptionsQuery)

    return !!subscriptions.docs.length
}

const checkIsLifetimeUser = async (userId: string) => {
    const paymentsQuery = query(
        collection(
            firebase.firestore,
            FIRESTORE_COLLECTIONS.USERS,
            userId,
            FIRESTORE_COLLECTIONS.PAYMENTS
        )
    )

    const payments = await getDocs(paymentsQuery)

    for (const paymentDoc of payments.docs) {
        const payment = paymentDoc.data()

        if (!payment?.items) continue

        const isLifetimePrice = payment.items.some(
            (item: any) => item?.price?.active && item?.price?.id === LIFETIME_PRICE_ID
        )

        if (isLifetimePrice) return true
    }

    return false
}

const checkIsPremiumUser = async (userId: string) => {
    const isSubscribedPremium = await checkUserSubscriptions(userId)

    if (isSubscribedPremium) return true

    return await checkIsLifetimeUser(userId)
}

const checkIsPremiumNotCanceledUser = async (userId: string) => {
    const subscriptionsQuery = query(
        collection(
            firebase.firestore,
            FIRESTORE_COLLECTIONS.USERS,
            userId,
            FIRESTORE_COLLECTIONS.SUBSCRIPTIONS
        ),
        where("status", "==", "active"),
        where("cancel_at_period_end", "==", false)
    )

    const subscriptions = await getDocs(subscriptionsQuery)

    return !!subscriptions.docs.length
}

export const premiumUserService = {
    checkIsPremiumUser,
    checkIsPremiumNotCanceledUser,
}
