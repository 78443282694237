import { Tooltip, Typography, TypographyProps } from "@mui/material"
import React from "react"
import { truncateText } from "../../../utils/strings"

interface Props extends TypographyProps {
    text: string
    length: number
}

const TypographyTruncated: React.FC<React.PropsWithChildren<Props>> = (props) => {
    const { text, length, ...rest } = props
    const [truncatedText, truncated] = truncateText(text, length)

    const truncatedTypography = <Typography {...rest}>{truncatedText}</Typography>
    const tooltipTypography = (
        <Typography {...rest} color={"inherit"}>
            {text}
        </Typography>
    )

    return (
        <>
            {truncated ? (
                <Tooltip title={tooltipTypography} disableTouchListener>
                    {truncatedTypography}
                </Tooltip>
            ) : (
                truncatedTypography
            )}
        </>
    )
}

export default TypographyTruncated
