import { Avatar, Box, Button, Card, SxProps, Theme, Typography } from "@mui/material"
import { FC, MouseEvent, useState } from "react"
import { ReferenceItem } from "../../../../editor"
import { MentionItem as MentionItemType } from "../../../../services/links/mentionsService"
import { MentionText } from "./MentionText"

interface Props {
    mentions: ReferenceItem["mentions"]
    openItemById: (id: string, state: any) => void
}

export const Mentions: FC<Props> = ({ mentions, openItemById }) => {
    return (
        <Box>
            {mentions.map((mention) => (
                <Box key={mention.id} px={1} py={0.5}>
                    <MentionItem mention={mention} openItemById={openItemById} />
                </Box>
            ))}
        </Box>
    )
}

interface MentionProps {
    mention: MentionItemType
    openItemById: (id: string, state?: any) => void
}

const MentionItem: FC<MentionProps> = ({ mention, openItemById }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const blocks = isExpanded ? mention.blocks : mention.blocks.slice(0, 2)
    const isShowMoreVisible = mention.blocks.length > 2 && !isExpanded

    return (
        <Card
            sx={styles.card}
            elevation={2}
            onClick={() => {
                openItemById(mention.id)
            }}
        >
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Typography variant="h6" sx={styles.name}>
                        {mention.name}
                    </Typography>
                    <Typography variant="caption" component="p" sx={styles.description}>
                        {mention.description}
                    </Typography>
                </Box>
                {mention.image && <Avatar variant="square" src={mention.image} sx={styles.image} />}
            </Box>
            {Boolean(mention.blocks.length) && (
                <>
                    <Box mt={1}>
                        {blocks.map(({ textBlocks, elementId }) => (
                            <MentionText
                                key={elementId}
                                textBlocks={textBlocks}
                                handleClick={(e: MouseEvent) => {
                                    e.stopPropagation()
                                    openItemById(mention.id, { elementId })
                                }}
                            />
                        ))}
                    </Box>
                    {isShowMoreVisible && (
                        <Box display="flex" justifyContent="center">
                            <Button
                                color="inherit"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setIsExpanded(true)
                                }}
                                size="small"
                            >
                                Show more
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </Card>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    card: {
        p: 1,
        cursor: "pointer",

        ":hover": {
            background: (theme) => theme.palette.action.hover,
        },
    },
    description: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
    name: {
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
        wordBreak: "break-word",
        fontSize: "1em",
        fontWeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
    image: {
        ml: 0.5,
        height: 48,
        width: 48,
        borderRadius: 1,
        border: "1px solid rgba(255, 255, 255, 0.16)",
        bgcolor: (theme) => theme.palette.text.secondary,
    },
}
