import { useBreadcrumbActions } from "hooks/useBreadcrumbActions"
import { uniq } from "lodash"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_DRAWER_TAG_IDS } from "storage/redux/drawer/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { GroupedTags } from "./useGroupedTags"

export const useSelectedTags = (tags: GroupedTags) => {
    const dispatch = useDispatch()
    const includeReferences = useSelector(
        (state: RootState) => state.drawer.typeSection.inlcudeReferences
    )
    const selectedTagIds = useSelector((state: RootState) => state.drawer.selectedTagIds)
    const { resetBreadcrumbs } = useBreadcrumbActions()

    const deselectTags = useCallback(
        (tagIds: string[]) => {
            const filteredTagIds = selectedTagIds.filter((tag) => !tagIds.includes(tag))
            dispatch({ type: SET_DRAWER_TAG_IDS, payload: filteredTagIds })
        },
        [selectedTagIds] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const getChildrenIds = (children: string[]) => {
        const childrenIds = []

        for (const child of children) {
            const tag = tags[child]

            if (!tag) continue
            if (!includeReferences && tag.isReference) continue

            childrenIds.push(child)
        }

        return childrenIds
    }

    const getAllChildren = (tagId: string) => {
        let children = getChildrenIds(tags[tagId].children)

        let i = 0
        while (i < children.length) {
            const tag = tags?.[children[i]]
            const childTagChildren = getChildrenIds(tag?.children || [])
            children = [...children, ...childTagChildren]
            i++
        }

        return children
    }

    const checkIfAllTagsSelected = (tagIds: string[]) => {
        return tagIds.every((tagId) => selectedTagIds.includes(tagId))
    }

    const toggleSelectTag = (tagId: string) => {
        const tagChildrenIds = getAllChildren(tagId)

        resetBreadcrumbs()

        if (selectedTagIds?.includes(tagId)) {
            const areAllChildrenSelected = checkIfAllTagsSelected(tagChildrenIds)
            deselectTags(areAllChildrenSelected ? [tagId, ...tagChildrenIds] : [tagId])
            return
        }

        const selected = selectedTagIds || []
        dispatch({
            type: SET_DRAWER_TAG_IDS,
            payload: uniq([...selected, tagId, ...tagChildrenIds]),
        })
    }

    return { toggleSelectTag, deselectTags, selectedTagIds }
}
