import { MAX_DESCRIPTION_LENGTH } from "../constants/editor"

export const truncateText = (text: string, length = MAX_DESCRIPTION_LENGTH) => {
    let truncatedText = text
    let truncated = false
    const words = text.split(" ")

    if (text.length > length) {
        truncated = true
        truncatedText = ""

        for (const word of words) {
            if (truncatedText.length >= length) {
                break
            }
            truncatedText += `${word} `
        }
        truncatedText = truncatedText.replace(/(,|:|;)*$/, "")
        truncatedText = `${truncatedText.trim()}...`
    }

    return [truncatedText, truncated]
}
