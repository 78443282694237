import { Model, Query, Relation } from "@nozbe/watermelondb"
import { children, field, relation } from "@nozbe/watermelondb/decorators"
import { BelongsToAssociation, HasManyAssociation } from "@nozbe/watermelondb/Model"
import { TYPES } from "../schema"
import { ItemModel } from "./ItemModel"

/**
 * @deprecated No longer in use (Use TagModel instead)
 */
export class TypeModel extends Model {
    static table = TYPES

    static associations = {
        parent: { type: "belongs_to", key: "parent_id" } as BelongsToAssociation,
        items: { type: "has_many", foreignKey: "type_id" } as HasManyAssociation,
    }

    @field("name") name: string
    @field("display") display: string
    @field("plural_display") pluralDisplay: string
    @field("wikidata_id") wikidataId: string
    @field("user_created") userCreated: boolean
    @field("is_saved") isSaved: boolean
    @children("items") items: Query<ItemModel>
    @relation("types", "parent_id") parent: Relation<TypeModel>
}
