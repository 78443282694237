export interface Source {
    id: string
    name: string
    identifier: string
}

export const getSource = (sourceName: string, sources: Source[]): Source | undefined => {
    for (let source of sources) {
        if (source.name === sourceName) {
            return source
        }
    }
}
