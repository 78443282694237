import { Box, Modal, Paper, SxProps, Theme } from "@mui/material"
import { Spinner, checkExtensionStatus, posthogService, useTabFocus } from "@recall/common"
import { BOOKMARKS_IMPORT_OPEN } from "constants/events"
import { FC, memo, useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_IMPORT_STATE } from "storage/redux/app/actionTypes"
import { importEnum } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"
import { BookmarksImport } from "../bookmarks/BookmarksImport"
import { InstallExtension } from "../bookmarks/InstallExtension"

const ImportModalComponent: FC = () => {
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isCreatingCards, setIsCreatingCards] = useState(false)

    const importState = useSelector((state: RootState) => state.app.importState)

    const dispatch = useDispatch()

    const getExtensionStatus = useCallback(async () => {
        if (isExtensionInstalled) return

        setIsLoading(true)
        const { isExtensionInstalled: isInstalled } = await checkExtensionStatus()
        setIsExtensionInstalled(isInstalled)
        setIsLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useTabFocus(getExtensionStatus)

    const captureOpenEvent = async () => {
        const { isExtensionInstalled } = await checkExtensionStatus()
        posthogService.captureEvent(BOOKMARKS_IMPORT_OPEN, { isExtensionInstalled })
    }

    useEffect(() => {
        captureOpenEvent()
        getExtensionStatus()
    }, [getExtensionStatus])

    const handleClose = () => {
        dispatch({
            type: SET_IMPORT_STATE,
            payload: isCreatingCards ? importEnum.IMPORTING_CLOSED : importEnum.CLOSED,
        })
    }

    const getChildren = () => {
        if (isExtensionInstalled)
            return (
                <BookmarksImport
                    isCreatingCards={isCreatingCards}
                    setIsCreatingCards={setIsCreatingCards}
                    onClose={handleClose}
                />
            )

        if (isLoading)
            return (
                <Box sx={styles.loader}>
                    <Spinner />
                </Box>
            )

        return <InstallExtension onClose={handleClose} />
    }

    const isImportingInBg = importState === importEnum.IMPORTING_CLOSED

    return (
        <Modal
            keepMounted
            open={!isImportingInBg}
            sx={{ ...styles.modal, visibility: isImportingInBg ? "hidden" : "visible" }}
            onClose={isImportingInBg ? undefined : handleClose}
        >
            <Paper sx={styles.container}>{getChildren()}</Paper>
        </Modal>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        width: 600,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
}

export const ImportModal = memo(ImportModalComponent)
