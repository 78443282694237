import { Box, Typography } from "@mui/material"
import {
    defaultOptions,
    LanguageSelect,
    SummaryLengthEnum,
    SummaryLengthForm,
    TranslateLanguageForm,
} from "@recall/common"
import { FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { summaryOptionsService } from "services/summaryOptionsService"
import { RootState } from "storage/redux/rootReducer"
import { UPDATE_USER } from "storage/redux/user/actionTypes"

export interface FormValues {
    language: string
    searchLanguage: string
    summaryLength: SummaryLengthEnum
}

interface Props {
    defaultValues: FormValues
}

export const SummaryOptionsForm: FC<Props> = ({ defaultValues }) => {
    const methods = useForm<FormValues>({
        mode: "onChange",
        defaultValues,
    })

    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.user.uid)
    const { watch } = methods

    const language = watch("language")
    const searchLanguage = watch("searchLanguage")
    const summaryLength = watch("summaryLength")

    const updateSummaryOptions = async () => {
        if (!language || !searchLanguage || !summaryLength) return

        const summaryOptions = await summaryOptionsService.getSummaryOptions(uid)

        if (
            summaryOptions.language !== language ||
            summaryOptions.searchLanguage !== searchLanguage ||
            summaryOptions.defaultLength !== summaryLength
        ) {
            dispatch({
                type: UPDATE_USER,
                payload: { language, searchLanguage, defaultLength: summaryLength },
            })
            await summaryOptionsService.updateSummaryOptions(uid, {
                language,
                searchLanguage,
                defaultLength: summaryLength,
            })
        }
    }

    useEffect(() => {
        updateSummaryOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, searchLanguage, summaryLength])

    return (
        <FormProvider {...methods}>
            <Box display="flex" flexDirection="column" mt={4}>
                <SummaryLengthForm />
            </Box>
            <Box display="flex" flexDirection="column" mt={4}>
                <TranslateLanguageForm />
            </Box>

            <Box display="flex" flexDirection="column" mt={4}>
                <Typography fontWeight={500} variant="h5">
                    Search Language
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    This language will be used when you search for new knowledge cards within
                    Recall.
                </Typography>
                <LanguageSelect name="searchLanguage" options={defaultOptions} />
            </Box>
        </FormProvider>
    )
}
