import { IconButton, Tooltip } from "@mui/material"
import { FC, PropsWithChildren } from "react"
import { useIsMobile } from "../../../hooks/useIsMobile"
import {
    useMarkToolbarButton,
    useMarkToolbarButtonState,
} from "../../hooks/useMarkToolbarButtonState"

export interface MarkButtonProps {
    nodeType: string
    clear?: string | string[]
    tooltip?: string
}

export const MarkButton: FC<PropsWithChildren<MarkButtonProps>> = ({
    children,
    tooltip,
    nodeType,
    clear,
}) => {
    const state = useMarkToolbarButtonState({ clear, nodeType })
    const { props: buttonProps } = useMarkToolbarButton(state)
    const isMobile = useIsMobile()

    return (
        <Tooltip title={tooltip} disableTouchListener>
            <IconButton
                onClick={buttonProps.onClick}
                size={"small"}
                sx={{
                    color: (theme) =>
                        buttonProps.pressed
                            ? theme.palette.text.primary
                            : theme.palette.action.active,
                    backgroundColor: (theme) =>
                        buttonProps.pressed ? theme.palette.action.selected : undefined,
                    ":hover": {
                        backgroundColor: (theme) =>
                            isMobile && buttonProps.pressed
                                ? theme.palette.action.selected
                                : undefined,
                    },
                }}
            >
                {children}
            </IconButton>
        </Tooltip>
    )
}
