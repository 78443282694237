import { Box, Chip, Typography } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    NestedTagChip,
    TagModel,
    tagRepository,
    useIsMobile,
    useTagAncestors,
} from "@recall/common"
import { RERENDER_TAGS_EVENT } from "components/shared/tags/hooks/useGroupedTags"
import { isEqual, map } from "lodash"
import { FC, useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_DRAWER_TAG_IDS } from "storage/redux/drawer/actionTypes"
import { RootState } from "storage/redux/rootReducer"

const MIN_CHIPS_TO_SHOW_CLEAR_ALL = 1

interface TagsWithAncestors {
    tag: TagModel
    ancestors: TagModel[]
}

export const TagsBar: FC = () => {
    const db = useDatabase()
    const dispatch = useDispatch()
    const selectedTagIds = useSelector((state: RootState) => state.drawer.selectedTagIds)
    const isMobile = useIsMobile()
    const { getTagAncestors, getTagNesting } = useTagAncestors()

    const [tags, setTags] = useState<TagsWithAncestors[]>([])

    const getTags = useCallback(async () => {
        const selectedTags = await tagRepository.getByTagIds(db, selectedTagIds)

        const sortedTags = selectedTags.sort(
            (a, b) => selectedTagIds.indexOf(a.id) - selectedTagIds.indexOf(b.id)
        )
        const tagsWithAncestors: TagsWithAncestors[] = []
        for (const tag of sortedTags) {
            const ancestors = await getTagAncestors(tag)
            tagsWithAncestors.push({ tag, ancestors })
        }
        setTags(tagsWithAncestors)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db, selectedTagIds])

    const checkSelectedTags = async () => {
        const selectedTags = await tagRepository.getByTagIds(db, selectedTagIds)
        const existingTagsIds = map(selectedTags, "id")
        const filteredTagIds = selectedTagIds.filter((tagId) => existingTagsIds.includes(tagId))

        if (!isEqual(filteredTagIds, selectedTagIds))
            dispatch({ type: SET_DRAWER_TAG_IDS, payload: filteredTagIds })
    }

    useEffect(() => {
        checkSelectedTags()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getTags()
    }, [getTags])

    const handleRemove = (tagId: string) => {
        const filteredTagIds = selectedTagIds.filter((selectedTag) => selectedTag !== tagId)
        dispatch({ type: SET_DRAWER_TAG_IDS, payload: filteredTagIds })
    }

    useEffect(() => {
        document.addEventListener(RERENDER_TAGS_EVENT, getTags)

        return () => {
            document.removeEventListener(RERENDER_TAGS_EVENT, getTags)
        }
    }, [getTags])

    const handleClearAll = () => {
        dispatch({ type: SET_DRAWER_TAG_IDS, payload: [] })
    }

    return (
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={1} mb={tags.length ? 3 : 0}>
            {tags.length > 0 && !isMobile && (
                <Typography sx={{ fontSize: 12 }} alignSelf="center">
                    Filtered tags:
                </Typography>
            )}
            {tags.map(({ tag, ancestors }, index) => (
                <NestedTagChip
                    isMobile={isMobile}
                    key={tag.id + index}
                    size="medium"
                    tagNesting={getTagNesting(tag, ancestors)}
                    onDelete={() => handleRemove(tag.id)}
                />
            ))}
            {tags.length >= MIN_CHIPS_TO_SHOW_CLEAR_ALL && (
                <Chip onClick={handleClearAll} size="medium" label="Clear all" />
            )}
        </Box>
    )
}
