import { map } from "lodash"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { TagModel } from "../../../storage/watermelon/models/TagModel"
import { ROOT_TAG_ID } from "../../../storage/watermelon/repository"
import { sentry } from "../../../utils/sentry"

export const useTagAncestors = () => {
    const isMobile = useIsMobile()

    const getNesting = (tag: TagModel, ancestors: TagModel[]) => {
        return getTagNesting(tag, ancestors, isMobile)
    }

    return { getTagNesting: getNesting, getTagAncestors: (tag: TagModel) => getTagAncestors(tag) }
}

export const getTagNesting = (tag: TagModel, ancestors: TagModel[], isMobile: boolean) => {
    if (isMobile) return tag.name.length > 15 ? [tag.name.slice(0, 15).trim() + "..."] : [tag.name]

    if (!ancestors.length) return [tag.name]

    return [...map(ancestors, "name"), tag.name]
}

const getParent = async (tag: TagModel) => {
    try {
        return await tag.parent.fetch()
    } catch {
        sentry.captureMessage("Faild to fetch parent tag")
        return null
    }
}

export const getTagAncestors = async (tag: TagModel) => {
    if (tag.parent.id === ROOT_TAG_ID) return []

    const parent = await getParent(tag)

    if (!parent) return []

    const ancestors = [parent]
    let i = 0
    while (i < ancestors.length && i < 1000) {
        const tag = ancestors[i]

        const parent = await getParent(tag)
        if (parent && parent.id !== ROOT_TAG_ID) ancestors.push(parent)
        else break

        i++
    }

    return ancestors.reverse()
}
