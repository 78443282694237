import { SxProps, Theme } from "@mui/material"
import { CONTACT_US_EMAIL, posthogService, sentry } from "@recall/common"
import { LoadingButton } from "components/shared/buttons/LoadingButton"
import { CHECKOUT_BUTTON_CLICKED, CONTACT_US_FOR_BUSINESS } from "constants/events"
import { SIGNUP_PATH } from "constants/routes"
import copyToClipboard from "copy-to-clipboard"
import { FC, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { BillingPeriod, paymentService } from "services/paymentService"
import { RootState } from "storage/redux/rootReducer"

const useAction = (
    isFree: boolean,
    isBusiness: boolean,
    billingPeriod: BillingPeriod
): [string | null, () => void | null, boolean] => {
    const userId = useSelector((state: RootState) => state.user.uid)
    const [loading, setLoading] = useState(false)
    const isLoggedIn = Boolean(userId)
    const history = useHistory()
    const isPremiumUser = useSelector((state: RootState) => state.user.isPremiumUser)

    const handleSignUp = () => {
        history.push(SIGNUP_PATH)
    }

    const handlePurchase = async () => {
        setLoading(true)
        try {
            posthogService.captureEvent(CHECKOUT_BUTTON_CLICKED)
            if (isPremiumUser) {
                toast("You are already subscribed.", { type: "success" })
                return
            }
            await paymentService.createCheckout(userId, billingPeriod)
        } catch (error) {
            toast("Something went wrong. Please try again later.", { type: "error" })
            sentry.captureException(error)
        } finally {
            setLoading(false)
        }
    }

    const handleSignUpAndPurchase = () => {
        posthogService.captureEvent(CHECKOUT_BUTTON_CLICKED)
        history.push(SIGNUP_PATH, { redirectToCheckout: billingPeriod })
    }

    const handleContactUs = () => {
        posthogService.captureEvent(CONTACT_US_FOR_BUSINESS)
        copyToClipboard(CONTACT_US_EMAIL)
        toast("Email copied to clipboard.", { type: "success" })
    }

    if (isBusiness) return ["Contact us", handleContactUs, loading]
    if (isFree && !isLoggedIn) return ["Try it now", handleSignUp, loading]
    if (!isFree && isLoggedIn) return ["Purchase now", handlePurchase, loading]
    if (!isFree && !isLoggedIn) return ["Sign up & Purchase", handleSignUpAndPurchase, loading]

    return [null, null, loading]
}

export const ActionButton: FC<{
    isFree: boolean
    isBusiness: boolean
    billingPeriod: BillingPeriod
}> = ({ isFree, isBusiness, billingPeriod }) => {
    const [title, callback, loading] = useAction(isFree, isBusiness, billingPeriod)

    if (!title || !callback) return null

    return (
        <LoadingButton
            sx={styles.button}
            onClick={callback}
            variant="contained"
            loading={loading}
            fullWidth
        >
            {title}
        </LoadingButton>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    button: {
        backgroundColor: (theme) => theme.palette.primary.dark,
        "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.main,
        },
        "&.Mui-disabled": {
            backgroundColor: (theme) => theme.palette.primary.main,
        },
    },
}
