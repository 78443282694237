import { addMonths } from "date-fns"
import { posthogService } from "../postHog"
import { IUsageService, UsageService } from "./usageService"

const MONTHLY_COST_USAGE = "Monthly cost usage"

export class CostUsageService extends UsageService implements IUsageService {
    limit = 5

    increaseUsage = async (userId: string, cost: number | null) => {
        const user = await this.userRepository.getUser(userId)
        const costUsage = cost || 0

        const shouldResetCostUsage =
            !user || !user?.usage?.cost || user.usage.cost.resetDate <= Date.now()

        if (shouldResetCostUsage) {
            await this.resetCostUsage(userId, costUsage)
            return costUsage
        }

        const newCost = user.usage.cost.value + costUsage

        await this.userRepository.upsertUser(userId, {
            usage: this.getUserUsage(user, { cost: { ...user.usage.cost, value: newCost } }),
        })

        return newCost
    }

    resetCostUsage = async (userId: string, cost: number) => {
        const user = await this.userRepository.getUser(userId)

        const resetDate = addMonths(Date.now(), 1).getTime()
        await this.userRepository.upsertUser(userId, {
            usage: this.getUserUsage(user, { cost: { value: cost, resetDate } }),
        })
    }

    isUsageExceeded = async (userId: string) => {
        const user = await this.userRepository.getUser(userId)

        const shouldResetCostUsage =
            !user || !user?.usage?.cost || user.usage.cost.resetDate <= Date.now()

        if (shouldResetCostUsage) {
            const currentUsage = user?.usage?.cost
            if (currentUsage)
                posthogService.captureEvent(MONTHLY_COST_USAGE, { usage: currentUsage })
            await this.resetCostUsage(userId, 0)
            return false
        }

        return user.usage.cost.value > this.limit
    }

    getUsage = async (userId: string) => {
        const user = await this.userRepository.getUser(userId)

        const usage = user?.usage?.cost?.value || 0

        return usage
    }
}
