import { Database } from "@nozbe/watermelondb"
import {
    SyncDatabaseChangeSet,
    synchronize,
    SyncPullArgs,
    SyncPushArgs,
} from "@nozbe/watermelondb/sync"
import { get, limitToFirst, orderByChild, query, ref, startAfter, startAt } from "firebase/database"
import { httpsCallable } from "firebase/functions"
import { v4 as uuidv4 } from "uuid"
import { firebase, posthogService } from "../../services"
import { sentry } from "../../utils"
import { COLLECTIONS } from "./schema"

interface PushDatabaseChanges {
    changes: SyncDatabaseChangeSet
    sessionId: string
    lastPulledAt: number
}

export async function syncRealTimeDb(
    database: Database,
    user_id: string,
    handlePushCallback: Function,
    handlePullCallback: Function
) {
    const sessionId = uuidv4()

    await synchronize({
        database,
        sendCreatedAsUpdated: true,
        pullChanges: async (data: any) =>
            await pullChanges(sessionId, user_id, data, handlePullCallback),
        pushChanges: async (data) => await pushChanges(sessionId, data, handlePushCallback),
    })
}

export const pushDatabaseChanges = httpsCallable<PushDatabaseChanges, void>(
    firebase.functions,
    "pushDatabaseChanges"
)

const CHUNK_SIZE = 10000

const fetchChunkedData = async (queryRef: any, lastPulledAt: number) => {
    let allValues: any = {}
    let lastKey: string | null = null
    let lastSyncedAt = lastPulledAt - 1

    while (true) {
        const queryConstraints = [
            orderByChild("last_synced_at"),
            lastKey ? startAfter(lastSyncedAt, lastKey) : startAt(lastSyncedAt),
        ]

        const snapshot = await get(
            query(queryRef, ...queryConstraints, limitToFirst(CHUNK_SIZE + (lastKey ? 1 : 0)))
        )
        if (!snapshot.exists() || snapshot.size === 0) break
        snapshot.forEach((snap) => {
            lastKey = snap.key
            lastSyncedAt = snap.val().last_synced_at
        })

        const values = snapshot.val()
        allValues = { ...allValues, ...values }
    }

    return allValues
}

const pullChanges = async (
    sessionId: string,
    user_id: string,
    { lastPulledAt = 0 }: SyncPullArgs,
    handlePullCallback: Function
) => {
    const syncTimestamp = new Date()
    let changes = {}

    await Promise.all(
        COLLECTIONS.map(async (collectionName) => {
            let updated: any = []
            let created: any = []
            let deleted: any = []

            const queryRef = ref(firebase.database, `${user_id}/${collectionName}/`)
            const values = await fetchChunkedData(queryRef, lastPulledAt)

            if (values) {
                updated = Object.values(values)
                    // @ts-ignore
                    .filter((data) => data.session_id !== sessionId)
                    // @ts-ignore
                    .filter((data) => data.is_deleted === false)

                deleted = Object.entries(values)
                    // @ts-ignore
                    .filter(([_, data]) => data.session_id !== sessionId)
                    // @ts-ignore
                    .filter(([_, data]) => data.is_deleted === true)
                    // @ts-ignore
                    .map(([key, _]) => key)
            }

            changes = {
                ...changes,
                [collectionName]: { created, deleted, updated },
            }
        })
    )
    await handlePullCallback()

    return { changes, timestamp: +syncTimestamp }
}

const LARGE_CHUNK_SIZE = "Chunk size bigger then 7MB"

const pushChanges = async (
    sessionId: string,
    { changes, lastPulledAt }: SyncPushArgs,
    handlePushCallback: Function
) => {
    const filteredChanges = filterUnsavedChanges(changes)
    const isEmpty = areChangesEmpty(filteredChanges)

    if (isEmpty) return

    let changesArray = [changes]

    if (getSize(changes) > maxSize) {
        try {
            posthogService.captureEvent(LARGE_CHUNK_SIZE)
            changesArray = chunkChanges(changes)
        } catch (error: any) {
            sentry.captureException(error)
            changesArray = [changes]
        }
    }

    for (const changes of changesArray)
        await pushDatabaseChanges({ changes, lastPulledAt, sessionId })

    await handlePushCallback()
}

const filterUnsavedChanges = (changes: SyncDatabaseChangeSet): SyncDatabaseChangeSet => {
    const filteredChanges: SyncDatabaseChangeSet = {}

    for (const [collectionName, collectionChanges] of Object.entries(changes)) {
        filteredChanges[collectionName] = {
            created: [],
            updated: [],
            deleted: [],
        }

        for (const actionType in collectionChanges) {
            const isDelete = actionType === "deleted"

            if (isDelete) {
                filteredChanges[collectionName][actionType] = collectionChanges[actionType]
                continue
            }

            // @ts-ignore
            filteredChanges[collectionName][actionType] = collectionChanges[actionType].filter(
                (action: { is_saved: boolean }) => action.is_saved === true
            )
        }
    }

    return filteredChanges
}

const areChangesEmpty = (changes: SyncDatabaseChangeSet): boolean => {
    for (const collection of Object.values(changes)) {
        for (const actions of Object.values(collection)) {
            if (actions.length > 0) {
                return false
            }
        }
    }
    return true
}

const maxSize = 7 * 1024 * 1024 // 7 MB

const getSize = (item: any) => {
    const itemSize = new TextEncoder().encode(JSON.stringify(item)).length

    return itemSize
}

const addItemsToChunk = (
    key: any,
    items: any,
    chunkKey: any,
    maxSize: any,
    currentChunk: any,
    currentSize: any,
    chunks: any[]
) => {
    for (const item of items) {
        const itemSize = getSize({ [chunkKey]: [item] })
        if (currentSize + itemSize > maxSize) {
            chunks.push(currentChunk)
            currentChunk = { [key]: { created: [], updated: [], deleted: [] } }
            currentSize = 0
        }
        currentChunk[key][chunkKey].push(item)
        currentSize += itemSize
    }
    return { currentSize, currentChunk }
}

const chunkChanges = (obj: any) => {
    const chunks: any[] = []
    let currentChunk: Record<string, any> = {}
    let currentSize = 0

    for (const key in obj) {
        const collection = obj[key]
        const created = collection["created"] || []
        const updated = collection["updated"] || []
        const deleted = collection["deleted"] || []

        currentChunk[key] = { created: [], updated: [], deleted: [] }
        ;({ currentSize, currentChunk } = addItemsToChunk(
            key,
            created,
            "created",
            maxSize,
            currentChunk,
            currentSize,
            chunks
        ))
        ;({ currentSize, currentChunk } = addItemsToChunk(
            key,
            updated,
            "updated",
            maxSize,
            currentChunk,
            currentSize,
            chunks
        ))
        ;({ currentSize, currentChunk } = addItemsToChunk(
            key,
            deleted,
            "deleted",
            maxSize,
            currentChunk,
            currentSize,
            chunks
        ))
    }

    if (Object.keys(currentChunk).length > 0 && currentSize > 0) {
        chunks.push(currentChunk)
    }

    return chunks
}
