import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import CancelIcon from "@mui/icons-material/Close"
import { Box, Fade, IconButton, MobileStepper, SxProps, Theme, alpha } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import { PopperProps } from "@mui/material/Popper"
import { getTheme, posthogService } from "@recall/common"
import { CANCEL_TUTORIAL_EVENT, COMPLETE_TUTORIAL_EVENT } from "constants/events"
import { isEqual } from "lodash"
import { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { SET_ACTIVE_TUTORIAL, TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"
import { useOpenOnboarding } from "../Onboarding/hooks/useOpenOnboarding"
import { TutorialPopup } from "./TutorialPopup"

export const HANDLE_NEXT_STEP_EVENT = "handle-next-step-event"

export interface Step {
    id?: string
    placement: PopperProps["placement"] | null
    component: Function
    anchorId: string | null
    showArrow: boolean
    isNextDisabled?: boolean
    onBack?: () => Promise<void>
}

interface Props {
    tutorialName: string
    steps: Step[]
}

const TutorialBaseComponent = (props: Props) => {
    const isCompleted = !!useSelector(
        (state: RootState) => state.user.tutorial.completed[props.tutorialName]
    )
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(0)
    const { isOnboardingOpen } = useOpenOnboarding()
    const maxSteps = props.steps.length
    const step = props.steps[activeStep]
    const isLastStep = activeStep === maxSteps - 1

    const handleNext = () => {
        if (isLastStep) {
            handleFinish()
            return
        }
        setActiveStep((activeStep) => activeStep + 1)
    }

    useEffect(() => {
        document.addEventListener(HANDLE_NEXT_STEP_EVENT, handleNext)

        return () => {
            document.removeEventListener(HANDLE_NEXT_STEP_EVENT, handleNext)
        }
    }, [isLastStep]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleBack = async () => {
        if (step.onBack) await step.onBack()
        setTimeout(() => setActiveStep((activeStep) => activeStep - 1))
    }

    const handleOnClose = (_: Event, reason: string) => {
        if (reason === "backdropClick") return
        close()
    }

    const close = () => {
        dispatch({ type: SET_ACTIVE_TUTORIAL, payload: "none" })
    }

    const handleFinish = () => {
        dispatch({ type: TUTORIAL_COMPLETE, payload: props.tutorialName })
        setActiveStep(0)
        posthogService.captureEvent(COMPLETE_TUTORIAL_EVENT, { tutorialName: props.tutorialName })
        close()
    }

    const handleCancel = () => {
        dispatch({ type: TUTORIAL_COMPLETE, payload: props.tutorialName })

        posthogService.captureEvent(CANCEL_TUTORIAL_EVENT, {
            tutorialName: props.tutorialName,
            tutorialStep: activeStep,
        })
        close()
    }

    if (isCompleted || isOnboardingOpen || !step) return null

    const stepContent = (
        <>
            <IconButton sx={styles.closeButton} size="small" onClick={handleCancel}>
                <CancelIcon fontSize="small" />
            </IconButton>
            {step.component({ goBack: handleBack, goNext: handleNext })}
            <MobileStepper
                sx={{
                    ...styles.stepper,
                    "&.MuiMobileStepper-positionStatic:not(button)": {
                        color: maxSteps === 1 && "background.paper",
                    },
                }}
                position="static"
                variant="text"
                steps={maxSteps}
                activeStep={activeStep}
                nextButton={
                    <Button
                        color={"primary"}
                        variant="contained"
                        size="small"
                        disabled={step.isNextDisabled}
                        onClick={handleNext}
                        endIcon={<KeyboardArrowRight />}
                    >
                        {isLastStep ? "Got it!" : "Next"}
                    </Button>
                }
                backButton={
                    <Button
                        sx={{ visibility: activeStep === 0 ? "hidden" : "visible" }}
                        color={"primary"}
                        variant="contained"
                        size="small"
                        onClick={handleBack}
                        startIcon={<KeyboardArrowLeft />}
                    >
                        Back
                    </Button>
                }
            />
        </>
    )

    if (step.anchorId)
        return (
            <Fade in timeout={500} key={activeStep.toString()}>
                <Box>
                    <TutorialPopup
                        key={step.id}
                        step={{ ...step, onBack: step.onBack || handleBack }}
                    >
                        {stepContent}
                    </TutorialPopup>
                </Box>
            </Fade>
        )

    return (
        <Dialog open onClose={handleOnClose} maxWidth={"sm"}>
            {stepContent}
        </Dialog>
    )
}

export const TutorialBase = memo(TutorialBaseComponent, (prev, next) => {
    return isEqual(prev.steps, next.steps) && prev.tutorialName === next.tutorialName
})

const styles: Record<string, SxProps<Theme>> = {
    stepper: {
        backgroundColor: "background.paper",
        color: (theme) => alpha(theme.palette.text.primary, 0.8),
    },
    closeButton: {
        position: "absolute",
        top: "5px",
        right: "5px",
        zIndex: 100,
    },
}

const theme = getTheme("dark")

export const commonTutorialStyles: Record<string, SxProps<Theme>> = {
    dialogContent: {
        p: 2,
    },
    popoverContent: {
        px: 1.5,
        py: 1,
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
    },
    heading: {
        fontSize: { xs: 20, sm: 24 },
        mb: 1,
        pr: 4,
    },
    body: {
        fontSize: {
            xs: 14,
            sm: 16,
            [theme.breakpoints.up("md")]: {
                minWidth: "300px",
            },
        },
    },
}
