import { Hidden, Drawer as MuiDrawer, SwipeableDrawer } from "@mui/material"
import { useIsMobile } from "@recall/common"
import { TagsTree } from "components/shared/tags/TagsTree"
import { DRAWER_WIDTH } from "constants/index"
import { useOpenItem } from "hooks/items/useOpenItem"
import { FC, memo, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_DRAWER_OPEN } from "storage/redux/drawer/actionTypes"
import { RootState } from "storage/redux/rootReducer"

const DrawerComp: FC = () => {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const isDrawerOpen = useSelector((state: RootState) => state.drawer.open)
    const { openItemById } = useOpenItem()

    useEffect(() => {
        dispatch({ type: SET_DRAWER_OPEN, payload: !isMobile })
    }, [dispatch, isMobile])

    const handleDrawerClose = () => {
        dispatch({ type: SET_DRAWER_OPEN, payload: false })
    }

    const handleClickItem = useCallback((id: string) => {
        openItemById(id, { reset: true })

        if (isMobile) {
            dispatch({ type: SET_DRAWER_OPEN, payload: false })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Hidden smUp>
                <SwipeableDrawer
                    variant="temporary"
                    anchor="left"
                    open={isDrawerOpen}
                    onClose={handleDrawerClose}
                    onOpen={() => null}
                    PaperProps={{ sx: styles.drawerPaper }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <TagsTree handleClickItem={handleClickItem} isSwipeable={true} />
                </SwipeableDrawer>
            </Hidden>
            <Hidden smDown>
                <MuiDrawer
                    variant="persistent"
                    anchor="left"
                    open={isDrawerOpen}
                    PaperProps={{ sx: styles.drawerPaper }}
                >
                    <TagsTree handleClickItem={handleClickItem} isSwipeable={false} />
                </MuiDrawer>
            </Hidden>
        </>
    )
}

export const Drawer = memo(DrawerComp)

const styles = {
    drawerPaper: {
        width: DRAWER_WIDTH,
        backgroundColor: (theme) => theme.palette.background.layout,
    },
}
