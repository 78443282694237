import { keyBy } from "lodash"
import { EditorBlockModel, EditorOrderModel } from "../models"

export const getOrderedModels = (
    editorBlocks: EditorBlockModel[],
    editorOrder: EditorOrderModel
) => {
    if (!editorOrder) return []

    const editorBlockById = keyBy(editorBlocks, "id")

    let value: EditorBlockModel[] = []

    for (const editorBlockId of editorOrder.order)
        if (editorBlockById[editorBlockId]) value.push(editorBlockById[editorBlockId])

    return value
}
