import { List, ListItemButton, ListItemText, Typography } from "@mui/material"
import { languages } from "@recall/common"
import { FC } from "react"

interface Props {
    currentLanguage?: string
    groupedSlugsByLanguage: Record<string, string>
    onClick: (slug: string, language: string) => void
}

export const SelectLanguageStep: FC<Props> = ({
    groupedSlugsByLanguage,
    currentLanguage = "en",
    onClick,
}) => {
    const languageCodes = Object.keys(groupedSlugsByLanguage).filter(
        (language) => language !== currentLanguage
    )

    if (!languageCodes.length)
        return <Typography>There are no translations available for this card.</Typography>

    return (
        <>
            <List sx={{ py: 0 }}>
                {languageCodes.map((languageCode) => (
                    <ListItemButton
                        sx={{ py: 0.5, px: 3 }}
                        onClick={() => onClick(groupedSlugsByLanguage[languageCode], languageCode)}
                        key={languageCode}
                    >
                        {languages?.[languageCode]?.flag} &nbsp;
                        <ListItemText primary={languages?.[languageCode]?.label} />
                    </ListItemButton>
                ))}
            </List>
        </>
    )
}
