import { v4 as uuidv4 } from "uuid"
import { EditorBlock, Image, Item, ItemPartial, Link, Source, Type } from "../../types"
import { ItemApi } from "./ItemApi"
import { LinkApi } from "./LinkApi"
import { TypeApi } from "./TypeApi"

const addId = (entity: object) => {
    return {
        ...entity,
        id: uuidv4(),
    }
}

export const toItem = (item: ItemApi): Item => {
    return {
        ...toItemPartial(item),
        links: item.links ? item.links.map(toLink) : [],
        mentions: [],
        markdown: item.markdown,
    }
}

export const toItemPartial = (item: ItemApi): ItemPartial => {
    const sources = item.sources.map(addId) as Source[]
    const editorBlocks = item.editorBlocks.map(addId) as EditorBlock[]
    const images = item.images.map(addId) as Image[]

    return {
        ...item,
        id: uuidv4(),
        type: item.type && toType(item.type),
        sources,
        images,
        editorBlocks,
        language: item.language,
        isExpanded: item.isExpanded,
        length: item.summaryLength,
    }
}

export const toType = (type: TypeApi): Type => {
    return {
        id: uuidv4(),
        ...type,
    }
}

export const toLink = (link: LinkApi): Link => {
    const item = toItemPartial(link.item)

    return {
        id: uuidv4(),
        item,
        property: link.property,
    }
}
