const formatLink = (url: string, text: string) => {
    return `[${text}](${url})`
}

const formatImage = (url: string, text?: string) => {
    return `![${text ?? ""}](${url})\n`
}

const formatHeading = (text: string, level: number) => {
    return `\n${"#".repeat(level)} ${text}\n`
}

export const markdownService = {
    formatLink,
    formatImage,
    formatHeading,
}
