import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    onSnapshot,
    query,
    where,
} from "firebase/firestore"
import { uniqBy } from "lodash"
import { ItemApi } from "../api"
import { firebase } from "../services"

export interface ExtensionItem {
    createdAt: number
    itemApi: ItemApi
    url: string
    expand?: boolean
    itemId?: string
}

export interface ExtensionItemDoc extends ExtensionItem {
    id: string
}

export class ExtensionItemsRepository {
    getExtensionItems = async (uid: string): Promise<ExtensionItemDoc[]> => {
        const querySnapshot = await getDocs(
            collection(firebase.firestore, `users/${uid}/extension_items`)
        )
        const documents: ExtensionItemDoc[] = []
        querySnapshot.forEach((doc) => {
            documents.push({ id: doc.id, ...doc.data() } as ExtensionItemDoc)
        })

        return documents
    }

    getExtensionItemByUrl = async (uid: string, url: string): Promise<ExtensionItemDoc | null> => {
        const querySnapshot = await getDocs(
            query(
                collection(firebase.firestore, `users/${uid}/extension_items`),
                where("url", "==", url)
            )
        )

        const doc = querySnapshot.docs?.[0]
        if (doc) {
            return { id: doc.id, ...doc.data() } as ExtensionItemDoc
        } else {
            return null
        }
    }

    createExtensionItem = async (uid: string, payload: Omit<ExtensionItem, "createdAt">) => {
        if (!uid) throw new Error("User id must be provided")

        const extensionItem: ExtensionItem = { ...payload, createdAt: Date.now() }

        await addDoc(collection(firebase.firestore, `users/${uid}/extension_items`), extensionItem)

        return extensionItem
    }

    deleteExtensionItem = async (uid: string, id: string) => {
        await deleteDoc(doc(firebase.firestore, `users/${uid}/extension_items`, id))
    }

    observeExtensionItems = (uid: string, callback: Function) => {
        const collectionRef = collection(firebase.firestore, `users/${uid}/extension_items`)

        const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
            const documents: ExtensionItemDoc[] = []
            const addedSnapshots = snapshot.docChanges().filter((change) => {
                return change.type === "added"
            })

            addedSnapshots.forEach((change) => {
                documents.push({ id: change.doc.id, ...change.doc.data() } as ExtensionItemDoc)
            })

            const uniqueExtensionItems = uniqBy(documents, "id")
            if (!!uniqueExtensionItems.length) callback(uniqueExtensionItems)
        })

        return unsubscribe
    }
}

export const extensionItemsRepository = new ExtensionItemsRepository()
