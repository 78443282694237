import * as Sentry from "@sentry/browser"
import { BrowserOptions } from "@sentry/react"
import { isDevelopment } from "./environment"

const sentryAppDsn = {
    web: "https://49a444f922ca4f8b950594de49bb160f@o1101435.ingest.sentry.io/6127317",
    extension:
        "https://d308c9834d364120b6bddbfd3a0c8161@o1101435.ingest.sentry.io/4504780139397120",
}

const captureException = (e: Error, data?: Record<string, unknown>) => {
    console.warn(e)

    if (isDevelopment()) {
        return
    }

    if (data) return Sentry.captureException(e, { contexts: { data } })

    Sentry.captureException(e)
}

const captureMessage = (message: string, data?: Record<string, unknown>) => {
    console.warn(message)

    if (isDevelopment()) {
        return
    }

    Sentry.captureMessage(message, data)
}

const captureEvent = (event: Sentry.Event) => {
    if (isDevelopment()) {
        return
    }

    Sentry.captureEvent(event)
}

const isInitialized = () => {
    return Boolean(Sentry.getCurrentHub().getClient())
}

const init = (app: "web" | "extension", release?: string, options: BrowserOptions = {}) => {
    if (isDevelopment() || isInitialized()) {
        return
    }

    Sentry.init({
        dsn: sentryAppDsn[app],
        release,
        ...options,
    })
}

export const sentry = { captureException, captureMessage, captureEvent, init }
