import { Box, Checkbox, Paper } from "@mui/material"
import { alpha, SxProps, Theme } from "@mui/material/styles"
import { ItemModel, useIsMobile } from "@recall/common"
import React, { MouseEvent } from "react"
import { ViewType } from "storage/redux/items/types"
import { GridLayout } from "./components/GridLayout"
import { ListLayout } from "./components/ListLayout"
import { useLongPressSelection } from "./hooks/useLongPressSelection"

export const ITEM_PARTIAL_DIV_ID = "id-item-partial-div"

interface Props {
    item: ItemModel
    onClick?: Function
    style?: {
        image?: SxProps<Theme>
        paper?: SxProps<Theme>
    }
    isSelected?: boolean
    isSelectionActive?: boolean
    view?: ViewType
    toggleSelected?: (id: string) => void
}

const ItemViewComponent: React.FC<Props> = ({
    onClick,
    item,
    style,
    isSelected,
    isSelectionActive,
    view = "grid",
    toggleSelected,
}) => {
    const isMobile = useIsMobile()
    const { handleTouchEnd, handleTouchStart } = useLongPressSelection(() => {
        if (toggleSelected) toggleSelected(item.id)
    })

    const handleOnClick = async (e: MouseEvent) => {
        if (isSelectionActive && toggleSelected) {
            e.stopPropagation()
            e.preventDefault()
            return toggleSelected(item.id)
        }

        if (onClick) {
            e.stopPropagation()
            await onClick(item)
        }
    }

    return (
        <Paper
            id={ITEM_PARTIAL_DIV_ID}
            elevation={0}
            sx={{
                ...(style?.paper
                    ? ({
                          ...styles.paper,
                          ...style.paper,
                      } as SxProps<Theme>)
                    : styles.paper),
                "&:hover": {
                    opacity: 0.9,

                    ".checkbox": {
                        display: isMobile ? undefined : "block",
                    },
                },
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchEnd}
            onMouseDown={(e) => e.preventDefault()}
            onClick={handleOnClick}
        >
            {view === "grid" ? (
                <GridLayout isSelected={isSelected} item={item} />
            ) : (
                <ListLayout isSelected={isSelected} item={item} />
            )}

            {toggleSelected && (
                <Box
                    className="checkbox"
                    sx={{
                        display: isSelectionActive || isSelected ? "block" : "none",
                    }}
                >
                    <Checkbox
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        onChange={() => toggleSelected(item.id)}
                        checked={isSelected}
                        sx={{
                            position: "absolute",
                            top: 4,
                            right: 4,
                            color: "white",
                            background: alpha("#000", 0.1),
                            "&:hover": {
                                background: alpha("#000", 0.2),
                            },
                            "&.Mui-checked": {
                                color: "white",
                            },
                        }}
                    />
                </Box>
            )}
        </Paper>
    )
}

export const ItemView = React.memo(ItemViewComponent)

const styles: Record<string, SxProps<Theme>> = {
    paper: {
        height: "100%",
        overflow: "hidden",
        position: "relative",
        cursor: "pointer",
        border: "1px solid rgba(255,255,255,0.06)",
        userSelect: "none",
        "& img": {
            pointerEvents: "none",
        },
        backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "rgba(255,255,255,0.06)" : theme.palette.action.hover,
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
    },
}
