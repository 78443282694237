import { EditorBlockData } from "../services/editorData/EditorBlockData"
import { EditorBlockModel } from "../storage/watermelon/models/EditorBlockModel"

export const extractSearchedText = (editorBlocks: EditorBlockModel[], searchText: string) => {
    for (const editorBlock of editorBlocks) {
        const text = extractSearchTextFromEditorBlocks(editorBlock, searchText)

        if (text) return text
    }
    return null
}

export const extractSearchTextFromEditorBlocks = (
    editorBlock: EditorBlockModel,
    searchText: string
) => {
    const text = EditorBlockData.getFormattedText([editorBlock])
    return extractSearchText(text, searchText)
}

export const extractSearchText = (text: string, searchText: string) => {
    const keywordIndex = text.toLowerCase().indexOf(searchText.toLowerCase())
    if (keywordIndex === -1) return null

    const textBeforeKeyword = text.substring(0, keywordIndex)
    const textAfterKeyword = text.substring(keywordIndex + searchText.length)

    const beforeTextWords = textBeforeKeyword.split(/\s+/)
    const afterTextWords = textAfterKeyword.split(/\s+/)

    const beforeWords = beforeTextWords.slice(-6)
    const afterWords = afterTextWords.slice(0, 6)

    const prefix =
        beforeTextWords.length > 6 && !textBeforeKeyword.trim().endsWith(".") ? "..." : ""
    const suffix = afterTextWords.length > 6 ? "..." : ""

    return {
        beforeSearch: prefix + beforeWords.join(" "),
        searchedText: text.substring(keywordIndex, keywordIndex + searchText.length),
        afterSearch: afterWords.join(" ") + suffix,
    }
}
