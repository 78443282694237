import { Box } from "@mui/material"
import { PlateElement, PlateElementProps } from "@udecode/plate-common"
import React from "react"
import { EDITOR_BLOCK_TEXT_DIV_ID } from "../constants/ids"

export const ParagraphElement = React.forwardRef<
    React.ElementRef<typeof PlateElement>,
    PlateElementProps
>(({ className, children, ...props }: PlateElementProps, ref) => {
    return (
        <PlateElement id={EDITOR_BLOCK_TEXT_DIV_ID} ref={ref} {...props}>
            <Box mb={0.5}>{children}</Box>
        </PlateElement>
    )
})
ParagraphElement.displayName = "ParagraphElement"
