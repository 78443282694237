import { Avatar, Box, Paper, Typography } from "@mui/material"
import { SxProps, Theme } from "@mui/material/styles"
import React from "react"
import { ReferenceItem } from "../../../editor"
import { Mentions } from "./components/Mentions"

interface Props {
    item: ReferenceItem
    onClick?: () => void
    openItemById: (id: string, state: any) => void
    style?: {
        image?: SxProps<Theme>
        paper?: SxProps<Theme>
    }
}

export const ItemPreview: React.FC<Props> = ({ onClick, item, style, openItemById }) => {
    return (
        <Paper
            elevation={0}
            sx={
                style?.paper
                    ? ({
                          ...styles.paper,
                          ...style.paper,
                      } as SxProps<Theme>)
                    : styles.paper
            }
        >
            <Box
                display="flex"
                justifyContent="space-between"
                height="100%"
                onClick={onClick}
                sx={styles.preview}
            >
                <Box
                    p={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    flex={3}
                    flexGrow={3}
                >
                    <Box>
                        <Typography sx={styles.name} variant="h6">
                            {item.name}
                        </Typography>

                        <Typography variant="body2" sx={styles.description}>
                            {item.description}
                        </Typography>
                    </Box>
                </Box>
                {item.image && <Avatar src={item.image} sx={styles.image} />}
            </Box>
            {item.mentions.length > 0 && (
                <Box>
                    <Box display="flex" pl={1} pb={0.5} gap={0.75} alignItems="center">
                        <Typography sx={styles.connectionsLabel}>
                            {item.mentions.length} Connection{item.mentions.length > 1 ? "s" : ""}
                        </Typography>
                    </Box>
                    <Mentions mentions={item.mentions} openItemById={openItemById} />
                </Box>
            )}
        </Paper>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    content: {
        py: 1,
        px: 1,
    },
    flexGrow: {
        flexGrow: 1,
    },
    preview: {
        cursor: "pointer",
        "&:hover": {
            opacity: 0.8,
        },
    },
    name: {
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
        lineHeight: 1.2,
        wordBreak: "break-word",
        fontSize: "1em",
        mb: 0.5,
        fontWeight: 600,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
    description: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        fontSize: "0.75em",
    },
    image: {
        borderRadius: 1,
        width: 50,
        height: 50,
        m: 1,
        bgcolor: (theme) => theme.palette.text.secondary,
    },
    marginRight: {
        mr: (theme) => theme.spacing(3),
    },
    paper: {
        all: "initial",
        maxHeight: "400px",
        height: "100%",
        overflow: "auto",
        position: "relative",
        border: (theme) => `1px solid ${theme.palette.action.hover}`,
        backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "rgba(255,255,255,0.06)" : theme.palette.action.hover,
        fontFamily: (theme) => theme.typography.secondaryFontFamily,
        fontSize: 16,
        color: (theme) => theme.palette.primary.contrastText,

        "&::-webkit-scrollbar": {
            height: 0,
            width: 0,
        },
    },
    mentions: {
        display: "flex",
        position: "absolute",
        right: 4,
        columnGap: 0.4,
        height: 30,
    },
    pin: {
        color: (theme) => theme.palette.grey[200],
    },
    pinIcon: {
        p: 0.3,
        transform: "rotate(45deg)",
        border: (theme) => `2px solid ${theme.palette.action.disabledBackground}`,
        borderRadius: "50%",
        background: (theme) => theme.palette.action.disabledBackground,
        color: (theme) => theme.palette.text.secondary,
        transition: "transform 0.2s",
        "&:hover": {
            transform: "scale(1.05)",
            background: (theme) => theme.palette.action.selected,
        },
    },
    connectionsLabel: { fontWeight: 600, opacity: 0.8 },
    icon: { opacity: 0.8 },
}
