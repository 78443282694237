import { Box, SxProps, Theme, Typography } from "@mui/material"
import { FC, Fragment, MouseEvent } from "react"

interface Mention {
    text: string
    isHighlighted?: boolean
}

interface Props {
    textBlocks: Mention[]
    handleClick: (e: MouseEvent) => void
}

export const MentionText: FC<Props> = ({ textBlocks, handleClick }) => {
    return (
        <Box sx={styles.mention} className="mention" onClick={handleClick}>
            <Box mr={1} ml={0.5} mt={1}>
                <Box sx={styles.dot} />
            </Box>

            <Typography variant="caption" component="p" sx={styles.text}>
                {textBlocks.map(({ text, isHighlighted }) => (
                    <Fragment key={text + isHighlighted}>
                        {isHighlighted ? (
                            <Box component="b" sx={styles.mentionText}>
                                {text}
                            </Box>
                        ) : (
                            text
                        )}
                    </Fragment>
                ))}
            </Typography>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    mention: {
        borderRadius: 1,
        display: "flex",
        mb: 0.5,
        ":hover": {
            background: (theme) => theme.palette.action.hover,
        },
    },
    text: {
        opacity: 0.8,
        fontSize: "0.75em",
    },
    mentionText: {
        opacity: 1,
    },
    dot: {
        p: 0.2,
        bgcolor: (theme) => theme.palette.text.primary,
        borderRadius: "50%",
    },
}
