import { KEYS_HEADING } from "@udecode/plate-heading"
import { ELEMENT_LI } from "@udecode/plate-list"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { v4 as uuidv4 } from "uuid"
import { MAX_DESCRIPTION_LENGTH } from "../../constants/editor"
import { YOUTUBE_TIMESTAMP } from "../../editor/plugins/youtube-timestamp"
import { EditorElements, MyRootBlock } from "../../editor/types"
import { toEditorBlock } from "../../storage/watermelon/helpers/editorBlocks"
import { EditorBlockModel } from "../../storage/watermelon/models/EditorBlockModel"
import { EditorOrderModel } from "../../storage/watermelon/models/EditorOrderModel"
import { getOrderedModels } from "../../storage/watermelon/services/editorBlocks"
import { ImageBlockData } from "./ImageBlockData"

export const EditorBlockData = {
    unwrapReference(referenceBlock: any) {
        referenceBlock.text = referenceBlock.children[0].text
        delete referenceBlock.type
        delete referenceBlock.children
        delete referenceBlock.connectionId
    },

    removeStaleReferences(editorBlocks: EditorElements, connectionIds: string[]) {
        for (let child of editorBlocks) {
            if (!child?.id) continue

            this.removeStaleReference(child.children as EditorElements, connectionIds, child.id)
        }
    },

    removeStaleReference(
        editorBlocks: EditorElements,
        connectionIds: string[],
        editorBlockId: string
    ) {
        for (let child of editorBlocks) {
            if (!child) continue

            if ("connectionId" in child) {
                const connectionId = child.connectionId as string
                child.editorBlockId = editorBlockId

                if (!connectionIds.includes(connectionId)) {
                    this.unwrapReference(child)
                }
            } else if ("children" in child) {
                this.removeStaleReference(
                    child.children as EditorElements,
                    connectionIds,
                    editorBlockId
                )
            }
        }
    },

    hasReference(editorBlock: any, connectionId: string): boolean {
        if (editorBlock.connectionId === connectionId) {
            return true
        } else if (editorBlock.children) {
            for (let child of editorBlock.children) {
                if (!child) continue

                const hasReference: boolean = this.hasReference(child, connectionId)

                if (hasReference === true) {
                    return hasReference
                }
            }
        }
        return false
    },

    getText(editorBlocks: any[], maxLength: number | null = MAX_DESCRIPTION_LENGTH) {
        let text = ""

        for (let child of editorBlocks) {
            if (!child || child?.type === YOUTUBE_TIMESTAMP) continue

            if (child.children) {
                text += " " + this.getText(child.children, maxLength)
            } else {
                text += " " + child.text
            }
            if (maxLength && text.length > maxLength) {
                return text
            }
        }

        return text.trim()
    },

    getTextWithoutHeadings(editorBlocks: any[], maxLength: number | null = MAX_DESCRIPTION_LENGTH) {
        let text = ""

        for (let child of editorBlocks) {
            if (!child || KEYS_HEADING.includes(child?.type) || child?.type === YOUTUBE_TIMESTAMP)
                continue

            if ([ELEMENT_PARAGRAPH, ELEMENT_LI].includes(child?.type)) {
                text += " "
            }

            if (child.children) {
                text += this.getTextWithoutHeadings(child.children, maxLength)
            } else {
                text += child.text
            }
            if (maxLength && text.length > maxLength) {
                return text.trim()
            }
        }

        return text.trim()
    },

    getFormattedText(editorBlocks: any[]) {
        const elements = this.getTextElements(editorBlocks)
        return elements.map(({ text }) => text).join("")
    },

    getTextElements(editorBlocks: any, endsWithDot = false) {
        let texts: any[] = []

        editorBlocks.forEach((child: any, index: number) => {
            if (child?.type === YOUTUBE_TIMESTAMP) {
                return
            }

            if (child.connectionId) {
                let text = child?.children?.length ? this.getText(child.children, null) : ""
                text = !Boolean(text) && Boolean(child.text) ? child.text : text

                texts = [
                    ...texts,
                    {
                        text: endsWithDot && index === 0 ? " " + text : text,
                        connectionId: child.connectionId,
                    },
                ]
            } else if (child.children) {
                const isLastEndOfSentence =
                    texts.length > 0 ? texts[texts.length - 1]?.text?.endsWith(".") : false
                texts = [...texts, ...this.getTextElements(child.children, isLastEndOfSentence)]
            } else {
                texts = [...texts, { text: (endsWithDot && index === 0 ? " " : "") + child.text }]
            }
        })

        return texts
    },

    getFirstImageBlock(editorBlocks: any[]) {
        for (let editorBlock of editorBlocks) {
            if (!editorBlock) continue

            if (editorBlock.type === "image" || editorBlock.type === "img") {
                return editorBlock
            }

            for (let child of editorBlock.children) {
                if (!child) continue

                if (child.type === "image" || child.type === "img") {
                    return child
                }
            }
        }
    },

    getImage(editorBlocks: EditorBlockModel[]) {
        const imageBlock = EditorBlockData.getFirstImageBlock(editorBlocks)

        let image = ""
        if (imageBlock) {
            image =
                imageBlock?.url ||
                imageBlock?.options?.url ||
                ImageBlockData.getUrl320(imageBlock) ||
                ""
        }
        return image
    },

    getOrdered(editorBlocks: EditorBlockModel[], editorOrder: EditorOrderModel) {
        const editorBlockModels = getOrderedModels(editorBlocks, editorOrder)
        return editorBlockModels.map((editorBlock: EditorBlockModel) => toEditorBlock(editorBlock))
    },

    create(text = "", type = "p") {
        return {
            id: uuidv4(),
            type,
            children: [{ text }],
        } as MyRootBlock
    },
}
