import { languages } from "../../constants"
import { SummaryLengthEnum, SummaryOptions, UserRepository } from "../../repositories"

export class SummaryOptionsService {
    userRepository: UserRepository

    constructor(userRepository: UserRepository) {
        this.userRepository = userRepository
    }

    getLanguageByCode = (languageCode: SummaryOptions["language"]) =>
        languages?.[languageCode]?.label

    getDefaultSummaryOptions = (): SummaryOptions => {
        return {
            language: "auto",
            searchLanguage: "en",
            defaultLength: SummaryLengthEnum.detailed,
        }
    }

    getSummaryOptions = async (uid: string): Promise<SummaryOptions> => {
        const user = await this.userRepository.getUser(uid)

        const defaultOptions = this.getDefaultSummaryOptions()

        const mergedOptions = {
            ...defaultOptions,
            ...user?.summaryOptions,
        }

        return mergedOptions
    }

    updateSummaryOptions = async (uid: string, options: SummaryOptions) => {
        await this.userRepository.upsertUser(uid, { summaryOptions: options })
    }
}
