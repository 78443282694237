import { sentry } from "../sentry"

export class HttpClientError extends Error {
    message: string
    details: Record<string, any>

    constructor(
        public status: number,
        public statusText: string,
        public url: string,
        details: Record<string, string>
    ) {
        super(`Error ${status} ${statusText} in ${url}`)
        this.message = details?.message || ""
        this.details = details || {}
    }
}

export class HttpClient {
    protected baseURL: string
    protected defaultHeaders: HeadersInit

    constructor(baseURL: string, defaultHeaders?: HeadersInit) {
        this.baseURL = baseURL
        this.defaultHeaders = defaultHeaders || {}
    }

    protected async initHeaders(headers: HeadersInit = {}): Promise<HeadersInit> {
        return { ...this.defaultHeaders, ...headers }
    }

    private async handleResponse(response: Response): Promise<Response> {
        if (!response.ok) {
            const error = await response.json()
            throw new HttpClientError(response.status, response.statusText, response.url, error)
        }
        return response
    }

    async get(path: string, headers: HeadersInit = { "Content-Type": "application/json" }) {
        try {
            const url = `${this.baseURL}${path}`
            const initHeaders = await this.initHeaders(headers)
            const response = await fetch(url, { method: "GET", headers: initHeaders })
            return this.handleResponse(response)
        } catch (err) {
            sentry.captureException(err as Error)
            throw err
        }
    }

    async post(
        path: string,
        body: BodyInit,
        headers: HeadersInit = { "Content-Type": "application/json" }
    ) {
        try {
            const url = `${this.baseURL}${path}`
            const initHeaders = await this.initHeaders(headers)
            const response = await fetch(url, {
                method: "POST",
                headers: initHeaders,
                body,
            })
            return this.handleResponse(response)
        } catch (err) {
            sentry.captureException(err as Error)
            throw err
        }
    }

    async head(url: string) {
        const response = await fetch(url, { method: "HEAD" })
        return Object.fromEntries(response.headers.entries())
    }
}
