import CloseIcon from "@mui/icons-material/Close"
import {
    alpha,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Fade,
    IconButton,
    Link,
    SxProps,
    Theme,
    Typography,
} from "@mui/material"
import { languages, SearchResults, SummaryLengthToggle, useIsMobile } from "@recall/common"
import { SETTINGS_PATH } from "constants/routes"
import useSearchItems, { Result } from "hooks/useSearchItems"
import { useSummaryLength } from "hooks/useSummaryLength"
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { summariesApi } from "services/api"
import { RootState } from "storage/redux/rootReducer"
import SearchInputComp from "../../../../shared/inputs/SearchInput"
import { ShortcutInfo } from "./ShortcutInfo"

interface SearchDialogProps {
    open: boolean
    onClose: () => void
    searchTerm: string
    setSearchTerm: (term: string) => void
    handleSubmit: () => void
    handleSelectResult: (result: Result) => void
}

export const SearchDialog: React.FC<SearchDialogProps> = ({
    open,
    onClose,
    searchTerm,
    setSearchTerm,
    handleSubmit,
    handleSelectResult,
}) => {
    const history = useHistory()
    const isMobile = useIsMobile()
    const language = useSelector((state: RootState) => state.user.searchLanguage)

    const { loading, results, isSummarizingUrl } = useSearchItems(searchTerm, {
        isSummarizationEnabled: true,
    })
    const ref = useRef<HTMLDivElement>(null)

    const { summaryLength, updateSummaryLength } = useSummaryLength()

    useEffect(() => {
        if (open) {
            setSearchTerm("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            PaperProps={{
                sx: {
                    ...styles.paper,
                    ...(isMobile && { top: 0, margin: 1 }),
                },
            }}
            slotProps={{
                backdrop: {
                    sx: styles.backdrop,
                },
            }}
        >
            <DialogTitle sx={styles.dialogTitle}>
                {!isMobile && <ShortcutInfo size="small" />}
                <Fade in={isSummarizingUrl}>
                    <Box sx={styles.summaryLengthToggleWrapper}>
                        <SummaryLengthToggle value={summaryLength} onChange={updateSummaryLength} />
                    </Box>
                </Fade>
                {isMobile && (
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent sx={styles.dialogContent}>
                <Box sx={styles.searchWrapper} ref={ref}>
                    <Typography variant="h6" sx={styles.instructions}>
                        Create a summary from any online content
                    </Typography>
                    <SearchInputComp
                        value={searchTerm}
                        onChange={setSearchTerm}
                        onPressEnter={handleSubmit}
                        placeholder="Paste a URL or type to search..."
                    />
                    <SearchResults
                        open={open}
                        value={searchTerm}
                        anchorEl={ref.current}
                        onClose={onClose}
                        loading={loading}
                        results={results}
                        onSelectResult={handleSelectResult}
                        getSummaryPreview={(url: string) => summariesApi.getSummaryPreview(url)}
                    />
                </Box>
            </DialogContent>
            <DialogContent sx={styles.languageWrapper}>
                <Typography variant="caption" sx={styles.language}>
                    You are searching in <b>{languages?.[language]?.label || "English"}</b>
                    <Link
                        component="span"
                        sx={styles.link}
                        onClick={() => history.push(SETTINGS_PATH)}
                    >
                        Change Language
                    </Link>
                </Typography>
            </DialogContent>
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    instructions: {
        textAlign: "center",
        color: (theme) => theme.palette.text.secondary,
        my: { xs: 1, md: 2 },
        fontWeight: 500,
        fontSize: { xs: "1.1rem", md: "1.25rem" },
    },
    paper: {
        backgroundColor: (theme) => theme.palette.background.default,
        position: "fixed",
        top: "10%",
        margin: "0 auto",
    },
    backdrop: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.2),
        backdropFilter: "blur(5px)",
    },
    link: {
        cursor: "pointer",
        ml: 1,
        color: (theme) => theme.palette.text.secondary,
        fontWeight: 500,
        textDecoration: "underline",
        "&:hover": {
            color: (theme) => theme.palette.text.primary,
        },
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    searchWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
    },
    languageWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid",
        borderColor: "divider",
        mt: 2,
        pt: 2,
    },
    language: {
        width: "100%",
        textAlign: "center",
        opacity: 0.9,
        color: (theme) => theme.palette.text.secondary,
    },
    dialogContent: {
        padding: 3,
    },
}
