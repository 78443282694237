import { Verified } from "@mui/icons-material"
import { Box, Button, Fade, SxProps, Theme, Typography, alpha } from "@mui/material"
import {
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    RECALL_EXTENSION_STORE_URL,
    Spinner,
    getTheme,
    isFirefox,
} from "@recall/common"
import { DarkLayout } from "components/layouts/DarkLayout"
import { HOME_PATH } from "constants/routes"
import { useExtensionAuth } from "hooks/auth/useExtensionAuth"
import { FC, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { extensionStateEnum } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"

export const LoggingInPage: FC = () => {
    const extensionState = useSelector((state: RootState) => state.app.extensionState)
    const { loginWithCustomToken } = useExtensionAuth()

    useEffect(() => {
        let interval = null

        if (extensionState === extensionStateEnum.NOT_INSTALLED) {
            interval = setInterval(() => {
                loginWithCustomToken()
            }, 1500)
        }

        return () => {
            if (interval) clearInterval(interval)
        }
    }, [extensionState]) // eslint-disable-line react-hooks/exhaustive-deps

    const renderMessage = () => {
        switch (extensionState) {
            case extensionStateEnum.LOGGED_IN:
                return (
                    <Fade in={true}>
                        <Box sx={styles.container}>
                            <Verified sx={{ width: 60, height: 60 }} color="secondary" />
                            <Typography sx={styles.text} variant="h6">
                                The browser extension is now logged in
                            </Typography>
                            <Button
                                sx={styles.button}
                                component={Link}
                                to={HOME_PATH}
                                color="secondary"
                                variant="contained"
                                fullWidth
                            >
                                Continue to App
                            </Button>
                        </Box>
                    </Fade>
                )
            case extensionStateEnum.LOADING:
                return (
                    <Fade in={true}>
                        <Box sx={styles.container}>
                            <Spinner />
                            <Typography sx={styles.text} variant="h6">
                                The browser extension is logging in
                            </Typography>
                        </Box>
                    </Fade>
                )
            case extensionStateEnum.NOT_INSTALLED:
                return (
                    <Fade in={true}>
                        <Box sx={styles.container}>
                            <Typography sx={styles.text} variant="h6">
                                The browser extension is not installed. Please install the extension
                                to proceed.
                            </Typography>
                            <Button
                                sx={styles.button}
                                target="_blank"
                                href={
                                    !isFirefox()
                                        ? RECALL_EXTENSION_STORE_URL
                                        : FIREFOX_RECALL_EXTENSION_STORE_URL
                                }
                                color="secondary"
                                variant="contained"
                                fullWidth
                            >
                                Install Extension
                            </Button>
                        </Box>
                    </Fade>
                )
            default:
                return (
                    <Fade in={true}>
                        <Box sx={styles.container}>
                            <Typography sx={styles.text} variant="h6">
                                Something went wrong and we could not log in your browser extension.
                                If this continues please contact support.
                            </Typography>
                            <Button
                                sx={styles.button}
                                component={Link}
                                to={HOME_PATH}
                                color="secondary"
                                variant="contained"
                                fullWidth
                            >
                                Go back
                            </Button>
                        </Box>
                    </Fade>
                )
        }
    }

    return <DarkLayout isContained>{renderMessage()}</DarkLayout>
}

const lightTheme = getTheme("light")

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        minHeight: "250px",
        gap: 2,
        py: 2,
        px: 3,
        maxWidth: 400,
    },
    button: {
        backgroundColor: lightTheme.palette.primary.light,
        color: alpha("#FFF", 0.8),
        "&:hover": {
            backgroundColor: alpha(lightTheme.palette.primary.light, 0.6),
        },
    },
    text: {
        textAlign: "center",
    },
}
