import { IUsageService, UsageService } from "./usageService"

export class BookmarksImportUsageService extends UsageService implements IUsageService {
    limit = 10

    increaseUsage = async (userId: string) => {
        const user = await this.userRepository.getUser(userId)

        const scrapingUsage = user?.usage?.scraping || 0
        const scraping = scrapingUsage + 1

        await this.userRepository.upsertUser(userId, {
            usage: this.getUserUsage(user, { scraping }),
        })

        return scraping
    }

    isUsageExceeded = async (userId: string) => {
        const usage = await this.getUsage(userId)
        return usage > this.limit
    }

    getUsage = async (userId: string) => {
        const user = await this.userRepository.getUser(userId)

        const usage = user?.usage?.scraping || 0

        return usage
    }
}
