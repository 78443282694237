import { buildUrl, HttpClient } from "../utils"

const EMAIL_VALIDATOR_PATH = "/email/validate/"

export class EmailValidatorApi {
    httpClient: HttpClient

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient
    }

    async validate(email: string) {
        const url = buildUrl(EMAIL_VALIDATOR_PATH, { email })

        try {
            const response = await this.httpClient.get(url)
            return await response.json()
        } catch {
            return null
        }
    }
}
