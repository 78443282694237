import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { date, field, json, readonly, relation, writer } from "@nozbe/watermelondb/decorators"
import { ITEMS, QUESTIONS, QUESTION_REVIEWS } from "../schema"
import { ItemModel } from "./ItemModel"
import { QuestionModel } from "./QuestionModel"

const sanitizeOptions = (data: any) => data

export class QuestionReviewModel extends Model {
    static table = QUESTION_REVIEWS

    static associations = {
        items: { type: "belongs_to", key: "item_id" } as BelongsToAssociation,
        questions: { type: "belongs_to", key: "question_id" } as BelongsToAssociation,
    }

    @field("is_saved") isSaved: boolean
    @field("question") question: string
    @field("answer") answer: string | null
    @json("options", sanitizeOptions) options: string[]
    @field("correct_answer") correctAnswer: string
    @field("is_correct") isCorrect: boolean

    @relation(ITEMS, "item_id") item: Relation<ItemModel>
    @relation(QUESTIONS, "question_id") reviewedQuestion: Relation<QuestionModel>

    @readonly @date("created_at") createdAt: Date
    @readonly @date("updated_at") updatedAt: Date

    prepareSave = () => {
        if (this.isSaved === false) {
            return this.prepareUpdate((record: QuestionReviewModel) => {
                record.isSaved = true
            })
        }

        return null
    }

    prepareDelete = () => {
        if (this._preparedState === null) {
            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    @writer async delete() {
        const task = this.prepareDelete()
        await this.batch(task)
    }
}
