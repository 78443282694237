export const FIRESTORE_COLLECTIONS = {
    USERS: "users",
    CHECKOUT_SESSIONS: "checkout_sessions",
    SUBSCRIPTIONS: "subscriptions",
    REFERRALS: "referrals",
    PROMO_CODES: "promo_codes",
    PAYMENTS: "payments",
    LIB_TAGS: "library_tags",
    LIB_ITEMS: "library_items",
    REFERRAL_HASH: "referral_hashes",
    SHARED_CARDS: "shared_cards",
    REVIEWS_SCHEDULE: "reviews_schedule",
    DELETED_USERS: "deleted_users",
}
