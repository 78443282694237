import { Database } from "@nozbe/watermelondb"
import { ELEMENT_IMAGE } from "@udecode/plate-media"

import { duplicateImage, ELEMENT_CUSTOM_IMAGE } from "../../../editor/plugins/image"
import { sentry } from "../../../utils"
import { ASSET_TYPES, EditorBlockModel, ItemModel } from "../models"
import { assetRepository } from "../repository/assetRepository"

const RERENDER_EDITOR_BLOCKS_EVENT = "rerender-editor-blocks-event"
const FIREBASE_STORAGE_BASE =
    "https://firebasestorage.googleapis.com/v0/b/recall-308915.appspot.com/o/user"

const isFirebaseStorageImage = (block: any) => {
    if (block.type !== ELEMENT_IMAGE) return false
    const imageUrl = block.url || block.options?.url
    return imageUrl.startsWith(FIREBASE_STORAGE_BASE)
}

const replaceImage = async (db: Database, block: any, item: ItemModel, uid: string) => {
    const imageUrl = block.url || block.options?.url

    const { url, size } = await duplicateImage(imageUrl, uid)

    if (url === null) return null

    await assetRepository.createAsset(db, {
        item,
        size,
        url,
        type: ASSET_TYPES.IMAGE,
    })

    return url
}

const replaceEditorBlockImageUrls = async (
    db: Database,
    editorBlocks: any,
    item: ItemModel,
    uid: string
) => {
    const blocks = []
    for (let block of editorBlocks) {
        if (block.children) {
            block.children = await replaceEditorBlockImageUrls(db, block.children, item, uid)
        }
        if (!isFirebaseStorageImage(block)) {
            if (block.type === ELEMENT_IMAGE || block.type === ELEMENT_CUSTOM_IMAGE) {
                const existingAssets = await assetRepository.getByUrlAndItemId(
                    db,
                    item.image,
                    item.id
                )

                if (!existingAssets?.length) {
                    await assetRepository.createAsset(db, {
                        item,
                        size: 0,
                        // @ts-ignore
                        url:
                            block?.url ||
                            block?.options?.url ||
                            block?.url_320 ||
                            block?.urlOriginal,
                        type: ASSET_TYPES.IMAGE_URL,
                    })
                }
            }
            blocks.push(block)
            continue
        }
        const url = await replaceImage(db, block, item, uid)

        if (!url) {
            continue
        }

        block.url = url
        block.options = block.options || {}
        block.options.url = url

        blocks.push(block)
    }

    return blocks.filter(Boolean)
}

export const createAssets = async (db: Database, item: ItemModel, uid: string) => {
    const links = await item.getLinkedItems()
    for (const link of [item, ...links]) {
        const editorBlocks = await link.editorBlocks.fetch()
        await replaceImageUrls(db, editorBlocks, link, uid)
    }
    if (typeof document !== "undefined")
        document.dispatchEvent(new CustomEvent(RERENDER_EDITOR_BLOCKS_EVENT))
}

export const replaceImageUrls = async (
    db: Database,
    editorBlocks: EditorBlockModel[],
    item: ItemModel,
    uid: string
) => {
    try {
        for (const editorBlock of editorBlocks) {
            if (isFirebaseStorageImage(editorBlock)) {
                const url = await replaceImage(db, editorBlock, item, uid)
                if (!url) {
                    editorBlock.delete()
                    continue
                }
                await editorBlock.updateUrl(url)
            } else if (
                editorBlock.type === ELEMENT_IMAGE ||
                editorBlock.type === ELEMENT_CUSTOM_IMAGE
            ) {
                const existingAssets = await assetRepository.getByUrlAndItemId(
                    db,
                    item.image,
                    item.id
                )

                if (!existingAssets?.length) {
                    await assetRepository.createAsset(db, {
                        item,
                        size: 0,
                        url: item.image,
                        type: ASSET_TYPES.IMAGE_URL,
                    })
                }

                // @ts-ignore
                if (editorBlock?.children?.length) {
                    const children = await replaceEditorBlockImageUrls(
                        db,
                        editorBlock.children,
                        item,
                        uid
                    )
                    await editorBlock.updateChildren(children)
                }
            }
            // @ts-ignore
            else if (editorBlock?.children?.length) {
                const children = await replaceEditorBlockImageUrls(
                    db,
                    editorBlock.children,
                    item,
                    uid
                )
                await editorBlock.updateChildren(children)
            }
        }
    } catch (error: any) {
        sentry.captureException(error)
    }
}
