// @ts-nocheck
import { v4 as uuid } from "uuid"
import {
    EXTENSION_INSTALLED,
    EXTERNAL_FIREFOX_MESSAGE,
    EXTERNAL_FIREFOX_MESSAGE_RESPONSE,
} from "../../constants"
import { EXTENSION_ID } from "../../settings"
import { isFirefox, sentry } from "../../utils"
import { IS_EXTENSION_LOGGED_IN } from "./messageTypes"
import { ExtensionMessage, ExtensionMessageResponse, IsExtensionLoggedInResponse } from "./types"

interface ExtensionStatus {
    isExtensionInstalled: boolean
    isExtensionLoggedIn: boolean
}

const EXTENSION_NOT_INSTALLED_MESSAGE = "Extension not installed"
const EXTENSION_NOT_INSTALLED_ERROR =
    "Error: Could not establish connection. Receiving end does not exist."
const EXTENSION_NOT_INSTALLED = "Could not establish connection. Receiving end does not exist."

export const checkExtensionStatus = async (): Promise<ExtensionStatus> => {
    try {
        const response = await sendMessageToExtension<IsExtensionLoggedInResponse>({
            type: IS_EXTENSION_LOGGED_IN,
        })

        return { isExtensionInstalled: true, isExtensionLoggedIn: response.isLoggedIn }
    } catch (err) {
        if (
            err !== EXTENSION_NOT_INSTALLED_MESSAGE &&
            err !== EXTENSION_NOT_INSTALLED_ERROR &&
            err !== EXTENSION_NOT_INSTALLED
        )
            sentry.captureException(err)
        return { isExtensionInstalled: false, isExtensionLoggedIn: false }
    }
}

const isChromeSendMessageDefined = () => {
    if (typeof chrome === "undefined") return false
    if (typeof chrome?.runtime === "undefined") return false
    if (typeof chrome?.runtime?.sendMessage === "undefined") return false

    return true
}

const checkIsScriptInjectedDelayed = async () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(!!document.getElementsByClassName(EXTENSION_INSTALLED).length)
        }, 2000)
    })
}

export function sendMessageToExtension<T extends ExtensionMessageResponse>(
    message: ExtensionMessage
): Promise<T> {
    return new Promise(async (resolve, reject) => {
        const isContentScriptInjected =
            !!document.getElementsByClassName(EXTENSION_INSTALLED).length
        if (isFirefox() || isContentScriptInjected) {
            if (!isContentScriptInjected) {
                const isInjectedDelayed = await checkIsScriptInjectedDelayed()
                if (!isInjectedDelayed) reject(EXTENSION_NOT_INSTALLED_MESSAGE)
            }

            const messageId = uuid()

            const timeoutId = setTimeout(() => {
                window.removeEventListener("message", listener)
                reject(new Error("Response timeout"))
            }, 5000)

            const listener = (event) => {
                if (
                    event.data.type === EXTERNAL_FIREFOX_MESSAGE_RESPONSE &&
                    event.data.messageId === messageId
                ) {
                    clearTimeout(timeoutId)
                    window.removeEventListener("message", listener)
                    resolve(event.data.response)
                }
            }

            window.addEventListener("message", listener)
            window.postMessage({
                type: EXTERNAL_FIREFOX_MESSAGE,
                message,
                messageId,
            })
        } else if (!isChromeSendMessageDefined()) {
            return reject(EXTENSION_NOT_INSTALLED_MESSAGE)
        } else {
            chrome.runtime.sendMessage(EXTENSION_ID, message, (response) => {
                if (chrome.runtime.lastError) {
                    reject(new Error(chrome.runtime.lastError.message))
                } else {
                    resolve(response)
                }
            })
        }
    })
}
