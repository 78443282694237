import {
    AppBar,
    Box,
    Dialog,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Slide,
    SxProps,
    Theme,
    Toolbar,
    Typography,
} from "@mui/material"
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote"
import {
    collapseSelection,
    focusEditor,
    toggleNodeType,
    usePlateEditorState,
} from "@udecode/plate-common"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"

import { Close } from "@mui/icons-material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import TextFieldsIcon from "@mui/icons-material/TextFields"
import { TransitionProps } from "@mui/material/transitions"
import {
    IconH1,
    IconH2,
    IconH3,
    IconH4,
    IconH5,
    IconH6,
    IconList,
    IconListNumbers,
    IconPilcrow,
    IconQuote,
} from "@tabler/icons-react"
import {
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
} from "@udecode/plate-heading"
import { ELEMENT_OL, ELEMENT_UL, unwrapList } from "@udecode/plate-list"
import React, { FC, PropsWithChildren, useState } from "react"
import { useIsMobile } from "../../../hooks/useIsMobile"
import { toggleIndentList } from "../../utils/indentList"

const items = [
    {
        value: ELEMENT_PARAGRAPH,
        label: "Paragraph",
        icon: <IconPilcrow />,
    },
    {
        value: ELEMENT_H1,
        label: "Heading 1",
        icon: <IconH1 />,
    },
    {
        value: ELEMENT_H2,
        label: "Heading 2",
        icon: <IconH2 />,
    },
    {
        value: ELEMENT_H3,
        label: "Heading 3",
        icon: <IconH3 />,
    },
    {
        value: ELEMENT_H4,
        label: "Heading 4",
        icon: <IconH4 />,
    },
    {
        value: ELEMENT_H5,
        label: "Heading 5",
        icon: <IconH5 />,
    },
    {
        value: ELEMENT_H6,
        label: "Heading 6",
        icon: <IconH6 />,
    },
    {
        value: ELEMENT_UL,
        label: "Bulleted list",
        icon: <IconList />,
    },
    {
        value: ELEMENT_OL,
        label: "Numbered list",
        icon: <IconListNumbers />,
    },
    {
        value: ELEMENT_BLOCKQUOTE,
        label: "Quote",
        icon: <IconQuote />,
    },
]

interface Props {
    isOpen: boolean
    setIsOpen: (_: boolean) => void
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export const TextTypeMenu: FC<Props> = ({ isOpen, setIsOpen }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const editor = usePlateEditorState()
    const isMobile = useIsMobile()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isMobile) {
            collapseSelection(editor)
            setIsOpen(true)
        } else {
            setAnchorEl(event.currentTarget)
        }
    }
    const handleClose = () => {
        if (isMobile) setIsOpen(false)
        else setAnchorEl(null)
    }

    const handleChange = (type: string) => {
        if (type === ELEMENT_UL || type === ELEMENT_OL) {
            toggleIndentList(editor, { listStyleType: type === ELEMENT_UL ? "disc" : "decimal" })
        } else {
            unwrapList(editor)
            toggleNodeType(editor, { activeType: type })
        }

        collapseSelection(editor)
        focusEditor(editor)

        if (isMobile) {
            setIsOpen(false)
        } else {
            setAnchorEl(null)
        }
    }

    const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

    return (
        <Box
            sx={{
                color: (theme) => theme.palette.action.active,
                ":hover": {
                    color: (theme) => theme.palette.text.primary,
                },
            }}
        >
            <IconButton
                onClick={handleClick}
                color="inherit"
                sx={{ position: "relative", pr: 1.5 }}
            >
                <TextFieldsIcon fontSize="small" />
                <Box
                    position="absolute"
                    top={"50%"}
                    right={-3}
                    sx={{ transform: "translateY(-40%)" }}
                >
                    {isOpen ? (
                        <ArrowDropUpIcon fontSize="small" />
                    ) : (
                        <ArrowDropDownIcon fontSize="small" />
                    )}
                </Box>
            </IconButton>
            <Wrapper
                anchorEl={anchorEl}
                handleClose={handleClose}
                isOpen={isMobile ? isOpen : Boolean(anchorEl)}
            >
                {items.map((item) => (
                    <MenuItem
                        sx={styles.menuItem}
                        key={item.value}
                        value={item.value}
                        onClick={() => {
                            handleChange(item.value)
                        }}
                    >
                        <ListItemIcon sx={styles.icon}>{item.icon}</ListItemIcon>
                        {item.label}
                    </MenuItem>
                ))}
            </Wrapper>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    icon: {
        color: (theme) => theme.palette.text.secondary,
    },
    menuItem: {
        borderRadius: 0.5,
    },
}

interface WrapperProps extends PropsWithChildren {
    anchorEl?: HTMLElement | null
    handleClose: () => void
    isOpen: boolean
}

const DesktopWrapper: FC<WrapperProps> = ({ children, anchorEl, handleClose, isOpen }) => {
    return (
        <Menu
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
        >
            {children}
        </Menu>
    )
}

const MobileWrapper: FC<WrapperProps> = ({ children, handleClose, isOpen }) => {
    return (
        <Dialog
            fullScreen
            open={isOpen}
            onClose={handleClose}
            PaperProps={{ sx: { background: (theme) => theme.palette.background.layout } }}
            TransitionComponent={Transition}
        >
            <AppBar position="relative">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Change text
                    </Typography>
                </Toolbar>
            </AppBar>
            {children}
        </Dialog>
    )
}
