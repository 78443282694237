import { Box, SxProps, Theme, ToggleButton, ToggleButtonGroup, alpha, Tooltip } from "@mui/material"
import { FC } from "react"

interface SummaryLengthToggleProps {
    value: string
    onChange: (value: string) => void
}

export const SummaryLengthToggle: FC<SummaryLengthToggleProps> = ({ value, onChange }) => {
    return (
        <ToggleButtonGroup
            value={value}
            exclusive
            onChange={(_, newValue) => newValue && onChange(newValue)}
            aria-label="summary length"
        >
            <Box sx={styles.container}>
                <Tooltip
                    title="Set summary length to create brief summaries with key points."
                    arrow
                >
                    <ToggleButton value="concise" aria-label="concise" sx={styles.toggleButton}>
                        Concise
                    </ToggleButton>
                </Tooltip>
                <Tooltip
                    title="Set summary length to create comprehensive summaries with more details."
                    arrow
                >
                    <ToggleButton value="detailed" aria-label="detailed" sx={styles.toggleButton}>
                        Detailed
                    </ToggleButton>
                </Tooltip>
            </Box>
        </ToggleButtonGroup>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.1),
        borderRadius: 1,
    },

    toggleButton: {
        minWidth: "100px",
        border: 0,
        borderRadius: 1,
        py: 0.6,
        px: 1,
        minHeight: 0,
        fontWeight: 500,

        "&.Mui-selected": {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            borderRadius: 1,
            "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.main,
            },
        },
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}
