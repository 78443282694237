import { Grid, Skeleton, Typography } from "@mui/material"
import React from "react"

interface Props {
    breakpoints?: object
}

const LoadingSkeleton: React.FC<React.PropsWithChildren<Props>> = ({ breakpoints }) => {
    if (!breakpoints) {
        breakpoints = {}
    }

    return (
        <Grid container spacing={2} justifyContent="center" direction="row" alignItems="center">
            <Grid item container spacing={2} {...breakpoints}>
                <Grid item xs={12}>
                    <Typography component="div" variant={"h4"}>
                        <Skeleton variant="rectangular" />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton height={200} variant="rectangular" />
                </Grid>
                <Grid item xs={12}>
                    <Typography component="div" variant={"caption"}>
                        <Skeleton variant="rectangular" />
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography component="div" variant={"caption"}>
                        <Skeleton variant="rectangular" />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="div" variant={"caption"}>
                        <Skeleton variant="rectangular" />
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="div" variant={"caption"}>
                        <Skeleton variant="rectangular" />
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography component="div" variant={"caption"}>
                        <Skeleton variant="rectangular" />
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography component="div" variant={"caption"}>
                        <Skeleton variant="rectangular" />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LoadingSkeleton
