import { Close, Translate } from "@mui/icons-material"
import { Box, Dialog, DialogContent, DialogTitle, IconButton, SxProps, Theme } from "@mui/material"
import { ItemApi, ItemModel, SourceModel, Spinner } from "@recall/common"
import { FC, useEffect, useState } from "react"
import { summariesApi } from "services/api"
import { wikiService } from "services/wikiService"
import { ConfirmLanguageStep } from "./ConfirmLanguageStep"
import { SelectLanguageStep } from "./SelectLanguageStep"

interface Props {
    item: ItemModel
    source: SourceModel
    closeModal: () => void
    handleUpdateItem: (itemApi: ItemApi, language: string) => void
}

export const LanguageModal: FC<Props> = ({ item, source, closeModal, handleUpdateItem }) => {
    const [groupedSlugsByLanguage, setGroupedSlugsByLanguage] = useState<Record<
        string,
        string
    > | null>(null)
    const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [itemApi, setItemApi] = useState<ItemApi | null>(null)

    const getLanguages = async () => {
        const slugsByLanguage = await wikiService.getGroupedSlugsByLanguage(source.identifier)
        setGroupedSlugsByLanguage(slugsByLanguage)
        setIsLoading(false)
    }

    const handleSelectLanguage = async (slug: string, language: string) => {
        setIsLoading(true)
        setSelectedLanguage(language)
        const itemApi = await summariesApi.summarizeWikipediaPage(slug, language)
        setItemApi(itemApi)
        setIsLoading(false)
    }

    useEffect(() => {
        getLanguages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog
            PaperProps={{
                sx: styles.wrapper,
            }}
            onClose={closeModal}
            open
        >
            <DialogTitle
                variant="h6"
                pb={0.5}
                display="flex"
                justifyContent="space-between"
                columnGap={3}
            >
                <Box display="flex" alignItems="center" gap={1}>
                    <Translate /> Change language
                </Box>
                <IconButton sx={{ mr: -2, mt: -1 }} onClick={closeModal}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                {!isLoading ? (
                    itemApi ? (
                        <ConfirmLanguageStep
                            handleChange={handleUpdateItem}
                            itemApi={itemApi}
                            item={item}
                            selectedLanguage={selectedLanguage}
                        />
                    ) : (
                        <SelectLanguageStep
                            currentLanguage={item.language}
                            groupedSlugsByLanguage={groupedSlugsByLanguage}
                            onClick={handleSelectLanguage}
                        />
                    )
                ) : (
                    <Box sx={styles.loader}>
                        <Spinner />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    loader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        py: 3,
    },
    wrapper: {
        background: (theme) => theme.palette.background.default,
        border: (theme) => `1px solid ${theme.palette.background.paper}`,
    },
}
