import { Model, Relation } from "@nozbe/watermelondb"
import { BelongsToAssociation } from "@nozbe/watermelondb/Model"
import { date, field, json, readonly, relation, writer } from "@nozbe/watermelondb/decorators"
import { ITEMS, QUESTIONS } from "../schema"
import { ItemModel } from "./ItemModel"

const sanitizeOptions = (data: any) => data

export class QuestionModel extends Model {
    static table = QUESTIONS

    static associations = {
        items: { type: "belongs_to", key: "item_id" } as BelongsToAssociation,
    }

    @field("is_saved") isSaved: boolean
    @field("question") question: string
    @field("answer") answer: string | null
    @json("options", sanitizeOptions) options: string[]
    @field("correct_answer") correctAnswer: string
    @date("next_review_time") nextReviewTime: Date | null

    @relation(ITEMS, "item_id") item: Relation<ItemModel>

    @readonly @date("created_at") createdAt: Date
    @readonly @date("updated_at") updatedAt: Date
    prepareSave = () => {
        if (this.isSaved === false) {
            return this.prepareUpdate((record: QuestionModel) => {
                record.isSaved = true
            })
        }

        return null
    }

    prepareDelete = () => {
        if (this._preparedState === null) {
            if (this.isSaved) {
                return this.prepareMarkAsDeleted()
            } else {
                return this.prepareDestroyPermanently()
            }
        }
    }

    isCorrectAnswer = () => {
        return this.answer === this.correctAnswer
    }

    isCorrectOption = (option: string) => {
        return option === this.correctAnswer
    }

    isQuestionReviewed = () => {
        return this.nextReviewTime && this.nextReviewTime > new Date()
    }

    @writer async delete() {
        const task = this.prepareDelete()
        await this.batch(task)
    }
}
