import { Box, useTheme } from "@mui/material"
import React from "react"
import { assets } from "../../assets"

interface Props {
    size?: number
}

export const RecallPlus: React.FC<Props> = ({ size = 80 }) => {
    const theme = useTheme()
    const image = theme.palette.mode === "dark" ? assets.RECALL_PLUS_DARK : assets.RECALL_PLUS_LIGHT
    return <Box display="flex" component="img" alt={image.name} src={image.url} width={size} />
}
